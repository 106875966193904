import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { propTypes, defaultProps } from "components/IMSOrderCard/types";

const OrderDetailDialogAction = ({ setOpen, data }) => {
  return (
    <DialogActions>
      {(data?.status === "Pending" || data?.status === "Declined") && (
        <Button
          onClick={async () => {
            // const payload = {
            //   OrderNumber: data?.cart?.header[0]?.sessionid[0],
            //   Status: "Approved",
            //   Event: {
            //     action: "approved",
            //     time: moment().format("YYYY-MM-DD"),
            //   },
            // };
            // await dispatch(imsRetailOrderUpdateOrder(payload));
            // await dispatch(
            //   imsRetailOrderUpdateOrder({
            //     OrderNumber: data?.cart?.header[0]?.sessionid[0],
            //     Status: "Processed",
            //     Event: {
            //       action: "processed",
            //       time: moment().format("YYYY-MM-DD"),
            //     },
            //   })
            // );
            // const shippingCountry =
            //   data?.cart?.header[0]?.shippinginfo[0]?.country[0];
            // const orderId = data?.cart?.header[0]?.sessionid[0];
            // let items = "";
            // for (let i = 0; i < data?.cart?.item.length; i++) {
            //   const orderLineItem = data?.cart?.item[i];
            //   items += `<Item>
            //       <SKU>[${
            //         orderLineItem?.suppitemnum[0]
            //       }] ${orderLineItem?.productname[0].replace(
            //     regExpr,
            //     ""
            //   )}</SKU>
            //       <Descrip>[EMB][${orderLineItem?.customfield1[0]?.value[0]?.replace(
            //         regExpr,
            //         ""
            //       )} / ${orderLineItem?.customfield2[0]?.value[0]?.replace(
            //     regExpr,
            //     ""
            //   )} / ${orderLineItem?.customfield3[0]?.value[0]?.replace(
            //     regExpr,
            //     ""
            //   )} / ${orderLineItem?.customfield4[0]?.value[0]?.replace(
            //     regExpr,
            //     ""
            //   )}] ${orderLineItem?.productname[0]?.replace(
            //     regExpr,
            //     ""
            //   )}</Descrip>
            //       <Color>${orderLineItem.color[0]?.replace(
            //         regExpr,
            //         ""
            //       )}</Color>
            //       <Qty>${orderLineItem.qty[0]}</Qty>
            //       <Size>${orderLineItem.customfield1[0]?.value[0]?.replace(
            //         regExpr,
            //         ""
            //       )}</Size>
            //       <Type>EMB</Type>
            //       <Style>${orderLineItem?.productname[0].replace(
            //         regExpr,
            //         ""
            //       )}</Style>
            //       <ProcessMode>Piece</ProcessMode>
            //       <Design>
            //         <Location>${
            //           orderLineItem?.customfield3[0]?.value[0] || "FRONT"
            //         }</Location>
            //         <Thumb>${orderLineItem?.quickpicthumblink[0]}</Thumb>
            //         <SourceFile>${
            //           orderLineItem?.imprintlogoartfile[0]
            //         }</SourceFile>
            //         <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
            //       </Design>`;
            // }
            // const xml = `<AddXml xmlns="http://tempuri.org/">
            //       <OrderString>
            //       <Request>
            //           <CustID>1</CustID>
            //           <PO>FICO-${orderId}</PO>
            //           <Source>Admin</Source>
            //           <Store>Big Oven Tees</Store>
            //           <StoreID>1</StoreID>
            //           <ShipTo>
            //               <FirstName>${data?.cart?.header[0]?.shippinginfo[0]?.name[0]?.replace(
            //                 regExpr,
            //                 ""
            //               )}</FirstName>
            //               <LastName></LastName>
            //               <Company></Company>
            //               <Adrx1>${data?.cart?.header[0]?.shippinginfo[0]?.address[0].replace(
            //                 regExpr,
            //                 ""
            //               )}</Adrx1>
            //               <Adrx2>${data?.cart?.header[0]?.shippinginfo[0]?.address2[0]?.replace(
            //                 regExpr,
            //                 ""
            //               )}</Adrx2>
            //               <City>${data?.cart?.header[0]?.shippinginfo[0]?.city[0]?.replace(
            //                 regExpr,
            //                 ""
            //               )}</City>
            //               <State>${data?.cart?.header[0]?.shippinginfo[0]?.state[0]?.replace(
            //                 regExpr,
            //                 ""
            //               )}</State>
            //               <Zip>${data?.cart?.header[0]?.shippinginfo[0]?.zip[0].replace(
            //                 regExpr,
            //                 ""
            //               )}</Zip>
            //               <Country>${shippingCountry?.replace(
            //                 regExpr,
            //                 ""
            //               )}</Country>
            //               <Email></Email>
            //           </ShipTo>
            //           <ShipFrom>
            //               <FirstName>Big Oven Tees</FirstName>
            //               <LastName></LastName>
            //               <Company>Big Oven Tees USA</Company>
            //               <Adrx1>8742 Buffalo Avenue</Adrx1>
            //               <Adrx2></Adrx2>
            //               <City>Niagara Falls</City>
            //               <State>NY</State>
            //               <Zip>14304</Zip>
            //               <Country>US</Country>
            //               <Email>info@bigoventees.com</Email>
            //             </ShipFrom>
            //           <ShipMethod>FedEx (FedEx Ground)</ShipMethod>
            //           <ProductionPriority>Normal</ProductionPriority>
            //           <Notes>SAGE ORDER ${orderId}</Notes>
            //           ${items}
            //       </Request>
            //   </OrderString>
            // </AddXml>`;
            // setOpen();
          }}
        >
          Approve
        </Button>
      )}
      {(data?.status === "Pending" || data?.status === "Processed") && (
        <Button
          onClick={async () => {
            // const payload = {
            //   OrderNumber: data?.cart?.header[0]?.sessionid[0],
            //   Status: "Declined",
            //   Event: {
            //     action: "declined",
            //     time: moment().format("YYYY-MM-DD"),
            //   },
            // };
            // await dispatch(imsRetailOrderUpdateOrder(payload));
            // await dispatch(
            //   cancelOrder({
            //     printProvider: "US",
            //     source: "Admin",
            //     orderId: `FICO-${data?.cart?.header[0]?.sessionid[0]}`,
            //   })
            // );
            setOpen();
          }}
        >
          Decline
        </Button>
      )}
      <Button onClick={setOpen}>Cancel</Button>
    </DialogActions>
  );
};

OrderDetailDialogAction.propTypes = propTypes;

OrderDetailDialogAction.defaultProps = defaultProps;

export default OrderDetailDialogAction;
