import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  QueryClient,
  QueryClientProvider,
  useIsFetching,
  useIsMutating,
} from "react-query";
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "root-store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Progress from "components/Progress";
import LoginPage from "pages/login";
import Orders from "pages/orders";
import Order from "pages/order";
import Calendar from "pages/calendar";
import VendorsPage from "pages/vendors";
import SettingsPage from "pages/settings";
import BotNetworkPage from "pages/bot-network";
import OrderRequestPage from "pages/order-request";
import DecorationsPage from "pages/decorations";
import IMSOrdersPage from "pages/ims-orders";
import CustomerTypePage from "pages/customer-type";
import VendorsUsersPage from "pages/vendors/users";
import PrivateRoute from "components/PrivateRoute";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import { AxiosInterceptor } from "services/http";
import { useFetch } from "hooks/useFetch";
import { APP_VERSION } from "constants/services";
import {
  LOGIN,
  SETTINGS,
  ORDERS,
  ORDER,
  CALENDAR,
  VENDORS,
  VENDORS_USERS,
  CUSTOMER_TYPE,
  BOT_NETWORK,
  DECORATIONS,
  ORDER_REQUEST,
  IMS_ORDERS,
} from "constants/routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Wrapper = () => {
  const dispatch = useDispatch();
  const appVersion = useSelector((state) => state?.appVersion?.version);
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const { refetch } = useFetch(APP_VERSION, APP_VERSION, { enabled: false });

  const compareVersion = async () => {
    const { data } = await refetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      await dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    }
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<SettingsPage />} path={SETTINGS} exact />
          <Route element={<Orders />} path={ORDERS} exact />
          <Route element={<Order />} path={ORDER} />
          <Route element={<Calendar />} path={CALENDAR} />
          <Route element={<VendorsPage />} path={VENDORS} />
          <Route element={<BotNetworkPage />} path={BOT_NETWORK} />
          <Route element={<DecorationsPage />} path={DECORATIONS} />
          <Route element={<OrderRequestPage />} path={ORDER_REQUEST} exact />
          <Route element={<IMSOrdersPage />} path={IMS_ORDERS} exact />
          <Route
            element={<VendorsUsersPage />}
            path={`${VENDORS_USERS}/:vendorName`}
          />
          <Route
            element={<Order />}
            path={`${ORDER}/:orderNumber/:vendorName`}
          />
        </Route>
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={CUSTOMER_TYPE} element={<CustomerTypePage />} />
      </Routes>
      {(!!isMutating || !!isFetching) && <Progress />}
    </>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PersistGate loading={null} persistor={persistor}>
            <AxiosInterceptor>
              <Wrapper />
            </AxiosInterceptor>
          </PersistGate>
        </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
