import styled from "styled-components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const Wrapper = styled.div``;

export const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  &.MuiPaper-root {
    background-color: #ffa50045;
  }

  :hover {
    background-color: #ffa50045;
  }
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    border-radius: 0;
  }
`;

export const StyledTitle = styled.h5`
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 5px 0;
`;

export const Note = styled.p`
  margin: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  color: red;
`;

export const StyledUpload = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const StyledHeader = styled.div`
  position: relative;
`;

export const CreateDecorationButton = styled(Button)`
  &.MuiButtonBase-root {
    position: absolute;
    right: 0;
    top: -5px;
  }
`;
