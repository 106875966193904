import LogoSm from "assets/images/logo-sm.png";
import { StyledSmLogo, StyledTitleWrapper } from "styles/components/header";

const Title = () => (
  <StyledTitleWrapper>
    <StyledSmLogo alt="logo" src={LogoSm} />
  </StyledTitleWrapper>
);

export default Title;
