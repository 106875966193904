import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "components/TextFieldInput";
import Snackbar from "components/SnackBar";
import UploadImage from "components/UploadImage";
import { usePost } from "hooks/usePost";
import { propTypes, defaultValue } from "pages/decorations/types";
import { CONFLUENCE_DECORATIONS } from "constants/services";
import { DialogBody, Note, StyledUpload } from "styles/pages/decorations";

const DecorationInformationDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const selectedDecoration = state?.selectedDecoration;
  const open = !!state?.selectedDecoration;
  const isEdit = selectedDecoration?.edit;

  const handleClose = () =>
    setState({
      ...state,
      selectedDecoration: undefined,
      snackbarMessage: undefined,
      snackbarMessageSeverity: undefined,
    });

  const onSubmit = async () => {
    const payload = {
      iconUrl: selectedDecoration?.iconUrl,
      decoFullName: selectedDecoration?.decoFullName,
      decoCode: selectedDecoration?.decoCode,
    };

    const { data } = await mutateAsync({
      url: CONFLUENCE_DECORATIONS,
      data: payload,
      isPut: isEdit,
    });

    if (!data?.success) {
      setState({
        ...state,
        snackbarMessage: data?.message,
        snackbarMessageSeverity: "error",
      });
      return;
    }

    setState({
      ...state,
      selectedDecoration: undefined,
      decorations: data?.decorations,
      snackbarMessage: undefined,
      snackbarMessageSeverity: undefined,
    });
  };

  const disabled = !selectedDecoration?.decoFullName?.trim();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Decoration Information</DialogTitle>
      <DialogContent>
        <DialogBody>
          {isEdit && (
            <StyledUpload>
              <UploadImage
                url={selectedDecoration?.iconUrl}
                folder="ConfluenceApp/Decorations"
                setUrl={(v) =>
                  setState({
                    ...state,
                    selectedDecoration: {
                      ...selectedDecoration,
                      iconUrl: v?.secure_url,
                    },
                  })
                }
              />
            </StyledUpload>
          )}

          <div>
            <TextField
              label="Decoration Code"
              value={selectedDecoration?.decoCode}
              handleChange={() => {}}
              fullWidth
              disabled
            />
            <Note>
              Note: This field is not editable as per required for database.
            </Note>
          </div>

          <TextField
            label="Decoration Full Name"
            fullWidth
            value={selectedDecoration?.decoFullName}
            handleChange={(e) => {
              const decoFullName = e?.target?.value;
              const value = {
                ...selectedDecoration,
                decoFullName,
              };

              if (!isEdit) {
                value.decoCode = decoFullName
                  ?.match(/\b(\w)/g)
                  ?.join("")
                  ?.toUpperCase();
              }

              setState({
                ...state,
                selectedDecoration: value,
              });
            }}
          />
        </DialogBody>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit} disabled={disabled}>
          Submit
        </Button>
      </DialogActions>

      {!!state?.snackbarMessage && (
        <Snackbar
          message={state?.snackbarMessage}
          onClose={() =>
            setState({
              ...state,
              snackbarMessage: null,
              snackbarMessageSeverity: null,
            })
          }
          severity={state?.snackbarMessageSeverity}
        />
      )}
    </Dialog>
  );
};

DecorationInformationDialog.propTypes = propTypes;

DecorationInformationDialog.defaultValue = defaultValue;

export default DecorationInformationDialog;
