import CryptoJS from "crypto-js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "components/Checkbox";
import PasswordInput from "components/PasswordInput";
import { usePost } from "hooks/usePost";
import { propTypes, defaultValue } from "pages/bot-network/types";
import { CRYPTO_KEY, BOT_NETWORK_USERS } from "constants/services";
import {
  StyledRow,
  StyledStatusHeading,
  StyledUserPassword,
} from "styles/pages/bot-network";

const UserInformationDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const selectedUser = state?.selectedUser;
  const open = !!selectedUser;

  const handleClose = () => setState({ ...state, selectedUser: undefined });

  const onSubmit = async () => {
    const payload = {
      email: selectedUser?.email,
      status: selectedUser?.status,
      password:
        selectedUser?.status === "Approved"
          ? CryptoJS.AES.encrypt(selectedUser?.password, CRYPTO_KEY).toString()
          : undefined,
    };

    const { data } = await mutateAsync({
      url: BOT_NETWORK_USERS,
      data: payload,
      isPut: true,
    });

    setState({ ...state, selectedUser: undefined, users: data });
  };

  const disabled =
    !selectedUser?.status ||
    (selectedUser?.status === "Approved" && !selectedUser?.password);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Decorator Information</DialogTitle>
      <DialogContent>
        <StyledRow>
          <div>Name :</div>
          <div>{selectedUser?.name}</div>
        </StyledRow>
        <StyledRow>
          <div>Email :</div>
          <div>{selectedUser?.email}</div>
        </StyledRow>
        <StyledRow>
          <div>Company Name :</div>
          <div>{selectedUser?.companyName}</div>
        </StyledRow>
        <StyledRow>
          <div>Company Website :</div>
          <div>{selectedUser?.companyWebsiteUrl}</div>
        </StyledRow>

        <StyledRow>
          <div>Phone Number :</div>
          <div>{selectedUser?.phoneNumber || "NA"}</div>
        </StyledRow>
        <StyledRow>
          <div>Tax Number :</div>
          <div>{selectedUser?.taxNumber || "NA"}</div>
        </StyledRow>

        <StyledRow>
          <div>Address :</div>
          <div>
            {`${selectedUser?.address1} ${selectedUser?.address2}, ${selectedUser?.city}, ${selectedUser?.state}, ${selectedUser?.country}, ${selectedUser?.zip}`}
          </div>
        </StyledRow>

        <StyledStatusHeading>Status</StyledStatusHeading>

        <div>
          <Checkbox
            label="Approved"
            checked={selectedUser?.status === "Approved"}
            onChange={(v) =>
              setState({
                ...state,
                selectedUser: {
                  ...state?.selectedUser,
                  status: v ? "Approved" : undefined,
                },
              })
            }
          />

          <Checkbox
            label="Declined"
            checked={selectedUser?.status === "Declined"}
            onChange={(v) =>
              setState({
                ...state,
                selectedUser: {
                  ...state?.selectedUser,
                  status: v ? "Declined" : undefined,
                  password: undefined,
                },
              })
            }
          />
        </div>

        {selectedUser?.status === "Approved" && !selectedUser?.alreadyApproved && (
          <StyledUserPassword>
            <PasswordInput
              label="Password"
              value={selectedUser?.password}
              setValue={(e) =>
                setState({
                  ...state,
                  selectedUser: {
                    ...state?.selectedUser,
                    password: e,
                  },
                })
              }
            />
          </StyledUserPassword>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit} disabled={disabled}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserInformationDialog.propTypes = propTypes;

UserInformationDialog.defaultValue = defaultValue;

export default UserInformationDialog;
