// export const BASE_URL = "https://656b-2607-fea8-b5f-25d0-d999-82a7-e005-f9b0.ngrok-free.app";
export const BASE_URL = "https://confluence-bot-app.herokuapp.com";
export const CRYPTO_KEY = "122BOTConfluence";
export const CONFLUENCES_USERS = "/v1/confluence-users";
export const CONFLUENCES_USER = "/v1/confluence-user";
export const LOGIN = "/v1/confluence-users/sign-in";
export const CONFLUENCE_USER_EVENTS = "/v1/confluence-users/events";
export const CONFLUENCE_VENDORS = "/v1/confluence-vendors";
export const CONFLUENCE_ORDERS = "/v1/confluence-orders";
export const CONFLUENCE_VENDORS_CLIENTS = `${CONFLUENCE_VENDORS}/clients`;
export const CONFLUENCE_NOTIFICATIONS = "/v1/confluence-notification";
export const DECORATIONS = "/v1/confluence-decorations";
export const PRINT_PROVIDERS = "/v1/confluence-print-providers";
export const COMMENTS = "/v1/confluence-orders-comments";
export const APP_VERSION = "/v1/update-app-version";
export const CONFLUENCE_PURCHASE_ORDER = "/v1/confluence-purchase-order";
export const CONFLUENCE_SCAN_PURCHASE_ORDER = "/v1/scan-purchase-order";
export const CONFLUENCE_OTHER_CHARGES = "/v1/confluence-other-charges";
export const QUICK_BOOKS_PDF_GENERATION = "/v1/quickBooks-estimate-pdf";
export const QUICK_BOOKS_CUSTOMER = "/v1/quickBooks-customers";
export const QUICK_BOOKS_ITEMS = "/v1/quickBooks-items";
export const QUICK_BOOKS_SALES_CODE = "/v1/quickBooks-sales-codes";
export const QUICK_BOOKS_ESTIMATE = "/v1/quickBooks-estimates";
export const QUICK_BOOKS_REFRESH_TOKEN = "/v1/qbRefresh";
export const QUICK_BOOKS_CA_AUTH_REQUEST = "/v1/qbCAAuthRequest";
export const QUICK_BOOKS_US_AUTH_REQUEST = "/v1/qbUSAuthRequest";
export const VENDORS_SERVICE = "/v1/confluence-vendors";
export const VENDOR_USERS = "/v1/confluence-vendor-users";
export const ORDERS_CHECK_IN = "/v1/confluence-orders-checkin";
export const ORDERS_DAMAGE = "/v1/confluence-orders-damage";
export const BOT_NETWORK_USERS = "/botnetwork/users";
export const CONFLUENCE_DECORATIONS = "/v1/confluence-decorations";
export const DECORATOR_COMMENTS = "/botnetwork/orders-decorator-comments";
export const CONFLUENCE_CUSTOMER_REQUEST = "/v1/confluence-customer/request";
export const CONFLUENCE_CUSTOMER_REQUEST_REPLY =
  "/v1/confluence-customer/request-reply";
export const CONFLUENCE_VENDORS_USER_LIST = "/v1/confluence-vendors-userList";
export const IMS_RETAIL_ORDERS = "/v1/confluence-orders/IMSRetail/Orders";
export const IMS_RETAIL_LOGOS = "/v1/confluence-orders/IMSRetail/Logos";
export const IMS_RETAIL_ORDER_EVENTS =
  "/v1/confluence-orders/IMSRetail/OrderEvents";
export const TRACKING_NUMBER_US = "/v1/confluence-orders/getTrackingUSNumber";
export const ADMIN_ORDER_UPDATE = "/v1/confluence-orders-admin-update";
export const ADMIN_ORDER_FILE_UPDATE =
  "/v1/confluence-orders-admin-file-update";
