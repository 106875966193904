import PropTypes from "prop-types";

export const propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.shape({
    cart: PropTypes.shape({
      item: PropTypes.arrayOf(PropTypes.shape({})),
      header: PropTypes.arrayOf(
        PropTypes.shape({
          sessionid: PropTypes.arrayOf(PropTypes.string),
          shippinginfo: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              address: PropTypes.string,
              address2: PropTypes.string,
              city: PropTypes.string,
              state: PropTypes.string,
              country: PropTypes.string,
              zip: PropTypes.string,
            })
          ),
        })
      ),
    }),
    status: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        time: PropTypes.string,
      })
    ),
  }),
};

export const defaultProps = {
  open: false,
  setOpen: () => {},
  data: {},
};
