import { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "components/Checkbox";
import TextFieldInput from "components/TextFieldInput";
import Datopicker from "components/Datepicker";
import FORM_DATEPICKER_FIELDS from "pages/order/fields-metadata";
import { completed } from "pages/orders/data";
import { usePost } from "hooks/usePost";
import { ADMIN_ORDER_UPDATE } from "constants/services";
import { MarkShipped } from "styles/components/order-card";
import {
  DecorationsInProductionDate,
  DatesTitle,
  EditOrderWrapper,
} from "styles/pages/order";

const EditOrderDialog = ({ open, setOpen, order }) => {
  const [state, setState] = useState();
  const handleClose = () => setOpen(false);
  const { mutateAsync } = usePost();

  useEffect(() => {
    if (!!order && !state)
      setState({
        ...order,
        decorations: order?.decorations?.map((c) => ({ title: c, value: c })),
        notifyClients: false,
      });
  }, [order, state]);

  const isOrderCompleted = state?.status === completed.value;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Edit Order</DialogTitle>
      <DialogContent>
        <EditOrderWrapper>
          <MarkShipped>
            {!!state && !!state?.decorations?.length && (
              <>
                <div>
                  <DatesTitle>In-Production Dates</DatesTitle>
                  <DecorationsInProductionDate
                    decorations={state?.decorations}
                    style={{ marginTop: 0 }}
                  >
                    {state?.decorations?.map((d) => {
                      const key = `${d?.title?.toLowerCase()}InProductionDate`;
                      const inProductionDisableKey = `${d?.title?.toLowerCase()}DisableInProductionDate`;
                      return (
                        <Datopicker
                          key={d?.title}
                          disabled={state[inProductionDisableKey]}
                          label={`${d?.title} InProductionDate`}
                          value={state[key] ? moment.unix(state[key]) : null}
                          handleChange={(v) =>
                            setState({ ...state, [key]: v?.unix() || null })
                          }
                        />
                      );
                    })}
                  </DecorationsInProductionDate>
                </div>

                <div>
                  <DatesTitle>Completed Dates</DatesTitle>
                  <DecorationsInProductionDate>
                    {state?.decorations?.map((d) => {
                      const key = `${d?.title?.toLowerCase()}CompletedDate`;
                      return (
                        <Datopicker
                          disabled={isOrderCompleted}
                          key={d?.title}
                          label={`${d?.title} CompletedDate`}
                          value={state[key] ? moment.unix(state[key]) : null}
                          handleChange={(v) =>
                            setState({
                              ...state,
                              [key]: v?.unix() || null,
                            })
                          }
                        />
                      );
                    })}
                  </DecorationsInProductionDate>
                </div>
              </>
            )}

            {!!state && (
              <div>
                <DatesTitle>Shipping Related Dates</DatesTitle>
                <DecorationsInProductionDate
                  decorations={state?.decorations}
                  style={{ marginTop: 0 }}
                >
                  {FORM_DATEPICKER_FIELDS?.map((f) =>
                    f?.key === "inProductionDate" ? null : (
                      <Datopicker
                        key={f?.label}
                        label={f?.label}
                        value={
                          state[f?.key] ? moment.unix(state[f?.key]) : null
                        }
                        handleChange={(v) =>
                          setState({ ...state, [f?.key]: v?.unix() || null })
                        }
                      />
                    )
                  )}

                  <Datopicker
                    label="Shipped Date"
                    value={moment.unix(state?.shippedDate)}
                    handleChange={(v) =>
                      setState({ ...state, shippedDate: v?.unix() || null })
                    }
                  />
                </DecorationsInProductionDate>
              </div>
            )}

            <Checkbox
              label={`Notify Clients ${
                !state?.notifyClients
                  ? "(End client will not recieve order update email)"
                  : "(Email will sent out to the customer)"
              }`}
              checked={state?.notifyClients}
              onChange={(notifyClients) =>
                setState({ ...state, notifyClients })
              }
            />
          </MarkShipped>

          <MarkShipped>
            <div>
              <DatesTitle>Total Stitch Count</DatesTitle>
              <DecorationsInProductionDate style={{ marginTop: 0 }}>
                <TextFieldInput
                  label="Total Stitch Count"
                  type="number"
                  value={state?.totalStitchCount}
                  handleChange={(e) =>
                    setState({
                      ...state,
                      totalStitchCount: Number(e?.target?.value),
                    })
                  }
                />
              </DecorationsInProductionDate>
            </div>

            <div>
              <DatesTitle>Total Print Locations</DatesTitle>
              <DecorationsInProductionDate style={{ marginTop: 0 }}>
                <TextFieldInput
                  label="Total Print Locations"
                  type="number"
                  value={state?.totalPrintLocations}
                  handleChange={(e) =>
                    setState({
                      ...state,
                      totalPrintLocations: Number(e?.target?.value),
                    })
                  }
                />
              </DecorationsInProductionDate>
            </div>

            <div>
              <DatesTitle>Total Peice Count</DatesTitle>
              <DecorationsInProductionDate style={{ marginTop: 0 }}>
                <TextFieldInput
                  label="Total Peice Count"
                  type="number"
                  value={state?.totalPeiceCount}
                  handleChange={(e) =>
                    setState({
                      ...state,
                      totalPeiceCount: Number(e?.target?.value),
                    })
                  }
                />
              </DecorationsInProductionDate>
            </div>
          </MarkShipped>
        </EditOrderWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={async () => {
            const payload = {
              notifyClients: state?.notifyClients,
              orderNumber: state?.orderNumber,
              vendorName: state?.vendorName,
              totalStitchCount: state?.totalStitchCount,
              totalPeiceCount: state?.totalPeiceCount,
              totalPrintLocations: state?.totalPrintLocations,
            };

            if (state?.shippedDate) {
              payload.shippedDate = state?.shippedDate;
            }

            for (let i = 0; i < state?.decorations.length; i += 1) {
              const d = state?.decorations[i];
              const completedDateKey = `${d?.title?.toLowerCase()}CompletedDate`;
              const inProductionKey = `${d?.title?.toLowerCase()}InProductionDate`;

              if (state[completedDateKey])
                payload[completedDateKey] = state[completedDateKey];

              if (state[inProductionKey])
                payload[inProductionKey] = state[inProductionKey];
            }

            for (let i = 0; i < FORM_DATEPICKER_FIELDS.length; i += 1) {
              const key = FORM_DATEPICKER_FIELDS[i]?.key;

              // eslint-disable-next-line no-continue
              if (key === "inProductionDate") continue;

              if (state[key]) payload[key] = state[key];
            }

            await mutateAsync({ url: ADMIN_ORDER_UPDATE, data: payload });
            setOpen(true);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditOrderDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
    decorations: PropTypes.arrayOf(PropTypes.string),
  }),
};

EditOrderDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  order: {},
};

export default EditOrderDialog;
