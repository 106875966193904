import Paper from "@mui/material/Paper";
import moment from "moment";
import Datepicker from "components/Datepicker";
import TextField from "components/TextFieldInput";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { StyledShipTo, ShipToRow, WorkOrderHeading } from "styles/pages/order";

const DecoratorWorkOrderGarmentData = ({ decoratorData, setDecoratorData }) => {
  const handleChange = (key, value) => {
    setDecoratorData({
      ...decoratorData,
      [key]: value,
    });
  };

  return (
    <Paper elevation={3}>
      <WorkOrderHeading>Garment Details</WorkOrderHeading>
      <StyledShipTo>
        <ShipToRow>
          <Datepicker
            label="Garment Sent Date"
            value={
              decoratorData?.garmentSentDate
                ? moment.unix(decoratorData?.garmentSentDate)
                : null
            }
            handleChange={(e) => handleChange("garmentSentDate", e?.unix())}
          />

          <TextField
            label="Tracking Number"
            value={decoratorData?.trackingNumber}
            handleChange={(e) =>
              handleChange("trackingNumber", e?.target?.value)
            }
          />

          <Datepicker
            label="Order Due Date"
            value={
              decoratorData?.orderDueDate
                ? moment.unix(decoratorData?.orderDueDate)
                : null
            }
            handleChange={(e) => handleChange("orderDueDate", e?.unix())}
          />
        </ShipToRow>
      </StyledShipTo>
    </Paper>
  );
};

DecoratorWorkOrderGarmentData.propTypes = PROP_TYPES;

DecoratorWorkOrderGarmentData.defaultProps = DEFAULT_STATE;

export default DecoratorWorkOrderGarmentData;
