import PropTypes from "prop-types";
import _ from "lodash";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const CustomTabs = ({ onTabChange, tabs, children, disabled, value }) => {
  const handleChange = (event, newValue) => {
    if (disabled) return;
    if (_.isFunction(onTabChange)) {
      onTabChange(newValue);
    }
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs?.map((t) => (
            <Tab label={t} key={t} disabled={disabled} />
          ))}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

CustomTabs.propTypes = {
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.number,
};

CustomTabs.defaultProps = {
  onTabChange: () => {},
  children: null,
  disabled: false,
  value: 0,
};

export default CustomTabs;
