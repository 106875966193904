import Paper from "@mui/material/Paper";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "components/TextFieldInput";
import Autocomplete from "components/Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  StyledTableRow,
  StyledTableRow1,
  StyledTableRow2,
  WorkOrderHeading,
  StyledDisabledByDefaultIcon,
  TextAreaWrapper,
} from "styles/pages/order";

const DecoratorWorkOrderLineItems = ({
  decoratorData,
  setDecoratorData,
  state,
}) => {
  const handleLineItemsChange = (key, value, index) => {
    setDecoratorData({
      ...decoratorData,
      lineItems: decoratorData?.lineItems?.map((row, j) => {
        if (index !== j) return row;
        return {
          ...row,
          [key]: value,
        };
      }),
    });
  };

  return (
    <Paper elevation={3}>
      <WorkOrderHeading>
        <div>Line Items</div>
        <AddCircleIcon
          sx={{ cursor: "pointer" }}
          onClick={() =>
            setDecoratorData({
              ...decoratorData,
              lineItems: [{}, ...decoratorData.lineItems],
            })
          }
        />
      </WorkOrderHeading>
      {decoratorData?.lineItems.map((row, i) => {
        const key = i;
        return (
          <StyledTableRow key={`editedLineItems_${key}`} even={i % 2 === 0}>
            <StyledDisabledByDefaultIcon
              onClick={() =>
                setDecoratorData({
                  ...decoratorData,
                  lineItems: decoratorData?.lineItems?.filter(
                    (r, j) => i !== j
                  ),
                })
              }
            />
            <StyledTableRow1>
              <TextField
                label="Description"
                error={!row?.description?.trim()}
                value={row?.description}
                handleChange={(e) =>
                  handleLineItemsChange("description", e?.target?.value, i)
                }
              />
            </StyledTableRow1>

            <StyledTableRow2>
              <TextField
                label="Quantity"
                error={!row?.qty}
                value={row?.qty}
                type="number"
                handleChange={(e) =>
                  handleLineItemsChange("qty", Number(e?.target?.value), i)
                }
              />

              <TextField
                label="Color"
                error={!row?.color?.trim()}
                value={row?.color}
                handleChange={(e) =>
                  handleLineItemsChange("color", e?.target?.value, i)
                }
              />

              <TextField
                label="Size"
                error={!row?.size?.trim()}
                value={row?.size}
                handleChange={(e) =>
                  handleLineItemsChange("size", e?.target?.value, i)
                }
              />

              <Autocomplete
                error={!row?.decoration}
                label="Decoration"
                value={row?.decoration}
                setValue={(c) => handleLineItemsChange("decoration", c, i)}
                inputValue={row?.decorationInput}
                setInputValue={(v) =>
                  handleLineItemsChange("decorationInput", v, i)
                }
                options={
                  state?.decorations?.map((u) => ({
                    ...u,
                    id: u?.value,
                    label: u?.value,
                  })) || []
                }
              />
            </StyledTableRow2>

            <StyledTableRow2>
              <TextAreaWrapper>
                <p>Additional Information</p>
                <TextareaAutosize
                  label="Additional Information"
                  style={{ width: "98%", backgroundColor: "inherit" }}
                  placeholder="Attention Information"
                  minRows={3}
                  value={row?.additionalInformation}
                  onChange={(e) =>
                    handleLineItemsChange(
                      "additionalInformation",
                      e?.target?.value,
                      i
                    )
                  }
                />
              </TextAreaWrapper>
            </StyledTableRow2>

            <StyledTableRow2>
              <TextField
                label="Decoration Cost"
                error={!row?.decorationCost}
                value={row?.decorationCost}
                type="number"
                handleChange={(e) =>
                  setDecoratorData({
                    ...decoratorData,
                    lineItems: decoratorData?.lineItems?.map((r, j) => {
                      if (i !== j) return r;
                      return {
                        ...r,
                        decorationCost: Number(e?.target?.value),
                        chargeAmount: Number(e?.target?.value) * row.qty,
                      };
                    }),
                  })
                }
              />

              <TextField
                label="Charge Amount"
                error={!row?.chargeAmount}
                value={row?.chargeAmount}
                disabled
              />
            </StyledTableRow2>
          </StyledTableRow>
        );
      })}
    </Paper>
  );
};

DecoratorWorkOrderLineItems.propTypes = PROP_TYPES;

DecoratorWorkOrderLineItems.defaultProps = DEFAULT_STATE;

export default DecoratorWorkOrderLineItems;
