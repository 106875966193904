import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "components/TextFieldInput";
import DialogContent from "@mui/material/DialogContent";
import { usePost } from "hooks/usePost";
import Snackbar from "components/SnackBar";
import OrderCardDialogTitle from "components/OrderCard/OrderCommentDialogTitle";
import { ORDERS_DAMAGE } from "constants/services";
import {
  CheckedInForm,
  DamageItemsHeader,
  DamagedLineItem,
  StyledRemoveCircleIcon,
} from "styles/components/order-card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DamageReportDialog = ({ open, setOpen, order, refresh }) => {
  const [state, setState] = useState({
    lineItems: [],
  });
  const { mutateAsync } = usePost();

  const fetchDamagedLineItems = async () => {
    if (order?.orderDamageReportData?.length > 0) {
      setState({ ...state, lineItems: order?.orderDamageReportData });
    } else {
      setState({
        ...state,
        lineItems: [{ itemDescription: "", damageQty: "", damageReason: "" }],
      });
    }
  };

  const onSubmit = async () => {
    const { data } = await mutateAsync({
      url: `${ORDERS_DAMAGE}/${order?.vendorName}/${order?.orderNumber}`,
      data: state?.lineItems?.length > 0 ? state?.lineItems : [],
    });

    if (data) {
      setState({
        ...state,
        lineItems:
          data?.length > 0
            ? data
            : [{ itemDescription: "", damageQty: "", damageReason: "" }],
        snackbarMessageSeverity: "success",
        snackbarMessage: "Damaged data submitted successfully !",
      });
      refresh();
    }
  };

  useState(() => {
    fetchDamagedLineItems();
  }, []);

  const handleLineItemChange = (key, value, index) =>
    setState({
      ...state,
      lineItems: state?.lineItems?.map((p, j) => {
        if (index !== j) return p;
        return { ...p, [key]: value };
      }),
    });

  const addNewLineItem = () =>
    setState({ ...state, lineItems: [...state.lineItems, {}] });

  const deleteLineItem = (index) => {
    setState({
      ...state,
      lineItems: state.lineItems.filter((_, i) => i !== index),
    });
  };

  const disabled = !!state?.lineItems?.filter(
    (l) =>
      !l?.itemDescription?.trim() || !l?.damageQty || !l?.damageReason?.trim()
  )?.length;

  return (
    <BootstrapDialog onClose={setOpen} open={open} fullWidth maxWidth="md">
      <OrderCardDialogTitle onClose={setOpen}>
        Report Damage ({order?.orderNumber})
      </OrderCardDialogTitle>
      <DialogContent dividers>
        <CheckedInForm style={{ border: "none", padding: 0 }}>
          <DamageItemsHeader>
            <p>Damaged Items</p>
            <Button variant="contained" onClick={addNewLineItem}>
              Add Items
            </Button>
          </DamageItemsHeader>

          {state?.lineItems?.map((l, i) => {
            const key = `damagedLineItem${i}`;

            return (
              <DamagedLineItem key={key}>
                <TextField
                  label="Item Description"
                  fullWidth
                  value={l?.itemDescription}
                  handleChange={(e) =>
                    handleLineItemChange("itemDescription", e?.target?.value, i)
                  }
                />

                <TextField
                  label="Damage Qty"
                  type="number"
                  fullWidth
                  value={l?.damageQty}
                  handleChange={(e) =>
                    handleLineItemChange("damageQty", e?.target?.value, i)
                  }
                />

                <TextField
                  label="Damage Reason"
                  fullWidth
                  value={l?.damageReason}
                  handleChange={(e) =>
                    handleLineItemChange("damageReason", e?.target?.value, i)
                  }
                />

                {state?.lineItems?.length > 1 && (
                  <StyledRemoveCircleIcon onClick={() => deleteLineItem(i)} />
                )}
              </DamagedLineItem>
            );
          })}
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={state?.lineItems?.length > 0 ? disabled : false}
          >
            Submit Damage Data
          </Button>
        </CheckedInForm>
      </DialogContent>

      {!!state?.snackbarMessage && (
        <Snackbar
          message={state?.snackbarMessage}
          onClose={() =>
            setState({
              ...state,
              snackbarMessage: null,
              snackbarMessageSeverity: null,
            })
          }
          severity={state?.snackbarMessageSeverity}
        />
      )}
    </BootstrapDialog>
  );
};

DamageReportDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
    orderDamageReportData: PropTypes.arrayOf({}),
  }),
};

DamageReportDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  order: { orderNumber: "", vendorName: "", orderDamageReportData: [] },
  refresh: () => {},
};

export default DamageReportDialog;
