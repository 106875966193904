import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  border: 1px solid #000000;
  border-radius: 5px;
  width: 50vw;
  padding: 10px;
`;

export const StyledLogo = styled.img`
  display: block;
  width: 20vw;
  margin: 0 auto;
`;

export const Title = styled.h1`
  text-align: center;
  margin: 10px 0;
  font-size: 24px;
  font-weight: 600;
`;

export const Footer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
  button {
    width: 120px;
  }
`;
