import { useState } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import getMenus from "components/Header/data";
import Logo from "assets/images/logo.png";
import {
  StyledAppsIcon,
  StyledLogo,
  DrawerLogo,
  StyledLink,
  UserInfo,
} from "styles/components/header";

const CustomDrawer = () => {
  const user = useSelector((state) => state?.user);
  const [open, setOpen] = useState(false);
  const MENUS = getMenus(user?.role);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const list = () => (
    <Box sx={{ width: 300 }} onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        <DrawerLogo divider>
          <StyledLogo alt="logo" src={Logo} />
        </DrawerLogo>

        {MENUS?.map(({ title, Icon, link }) => (
          <ListItem key={title + Math.random()} disablePadding divider>
            <StyledLink to={link}>
              <ListItemButton>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </StyledLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={toggleDrawer}
      >
        <StyledAppsIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer}>
        {list()}

        <UserInfo>
          <Typography className="name">{user?.fullName}</Typography>
          <Typography className="role-name">({user?.roleName})</Typography>
        </UserInfo>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
