import styled from "styled-components";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const StyledHeader = styled.div`
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)``;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  cursor: pointer;
`;

export const StyledVendors = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
`;

export const StyledVendor = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  padding: 20px 0;
  border-bottom: 2px solid #000;
  -webkit-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  -moz-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  height: 120px;
`;

export const StyledFlag = styled.img`
  width: 20px;
  height: 13px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const StyledEditIcon = styled(EditIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }
`;

export const StyledvendorName = styled.h4`
  font-size: 11px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  position: absolute;
  bottom: 5px;
  text-align: center;
`;

export const StyledAvatarWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledAvatar = styled.img`
  width: 40%;
  margin-bottom: 20px;
`;

export const UploadImageButton = styled(Button)``;

export const Note = styled.p`
  color: red;
  margin: 2px 0 10px;
  font-size: 11px;
  font-weight: bold;
`;

export const Row = styled.div`
  margin-bottom: 20px;
`;

export const VendorUsersListButton = styled(Button)`
  &.MuiButton-root {
    padding: 5px 10px;
    font-size: 10px;
    background: black;
    color: white;
    :hover {
      background: black;
      color: white;
    }
  }
`;
