import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import moment from "moment";
import _ from "lodash";
import { ORDER, ORDERS } from "constants/routes";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import TagsAutocomplete from "components/TagsAutocomplete";
import { DECORATIONS, CONFLUENCE_ORDERS } from "constants/services";
import { usePost } from "hooks/usePost";
import { inProduction } from "pages/orders/data";
import {
  StyledContainer,
  Wrapper,
  StyledHeader,
  PageTitle,
  Decorations,
} from "styles/pages/calendar";

const Calendar = () => {
  const navigate = useNavigate();
  const calendarRef = useRef();
  const { mutateAsync } = usePost();
  const [state, setState] = useState({
    decoration: { title: "DTG", value: "DTG" },
  });

  const getOrders = async (startDate, endDate) => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_ORDERS,
      data: {
        status: inProduction.value,
        decoration: state?.decoration?.value,
        startDate,
        endDate,
      },
    });

    if (data?.length > 0) {
      setState({ ...state, orders: data });
    }
  };

  useEffect(() => {
    if (calendarRef) {
      const startDate =
        calendarRef?.current?.getApi()?.currentDataManager?.data?.dateProfile
          ?.currentRange?.start;

      const endDate =
        calendarRef?.current?.getApi()?.currentDataManager?.data?.dateProfile
          ?.currentRange?.end;

      getOrders(startDate, endDate);
    }
  }, [state?.decoration]);

  return (
    <StyledContainer fixed>
      <Breadcrumbs sx={{ marginBottom: "10px" }}>
        <Link underline="hover" color="inherit" to={ORDERS}>
          Orders
        </Link>
        <Typography color="text.primary">
          Work Order (In-Production Orders)
        </Typography>
      </Breadcrumbs>

      <StyledHeader>
        <PageTitle>Work Order (In-Production Orders)</PageTitle>

        <Decorations>
          <TagsAutocomplete
            multiple={false}
            optionApiKey="decoCode"
            apiEndPoint={DECORATIONS}
            value={state?.decoration || { title: "", value: "" }}
            setValue={(decoration) => {
              if (decoration) {
                setState({ ...state, decoration });
              }
            }}
            label="Decorations"
          />
        </Decorations>
      </StyledHeader>

      <Wrapper>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          eventBackgroundColor="#000"
          eventBorderColor="#000"
          displayEventTime={false}
          eventClick={(e) => {
            const splitArr = e?.event?.title?.split(" ");
            const orderNumber = splitArr[splitArr.length - 1];
            if (orderNumber) {
              const order = state?.orders?.find(
                (o) => o?.orderNumber === orderNumber
              );

              navigate(`${ORDER}/${order?.orderNumber}/${order?.vendorName}`);
            }
          }}
          events={state?.orders?.map((o) => {
            const productionDate = moment.unix(
              o[`${state?.decoration?.value?.toLowerCase()}InProductionDate`]
            );

            const exists = state?.orders?.filter((p) => {
              const newProductionDate = moment
                .unix(
                  p[
                    `${state?.decoration?.value?.toLowerCase()}InProductionDate`
                  ]
                )
                ?.format("DD/MM/YYYY");

              return newProductionDate === productionDate.format("DD/MM/YYYY");
            });

            let finalProductionDate = moment(productionDate);

            if (exists === 1) {
              finalProductionDate = finalProductionDate
                .set("hour", 8)
                .set("minute", 0);
            } else {
              const index = _.findIndex(exists, [
                "orderNumber",
                o?.orderNumber,
              ]);
              finalProductionDate = finalProductionDate
                .set("hour", 8 + index)
                .set("minute", 0);
            }

            return {
              title: `[${o?.printProvider}] [${state?.decoration?.value}] ${o?.orderNumber}`,
              start: finalProductionDate.format(),
            };
          })}
          datesSet={(e) => getOrders(e?.startStr, e?.endStr)}
        />
      </Wrapper>
    </StyledContainer>
  );
};

export default Calendar;
