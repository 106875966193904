import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTabIndex: 0,
  page: 1,
};

export const imsOrdersSlice = createSlice({
  name: "imsOrders",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.selectedTabIndex = action?.payload?.selectedTabIndex;
      state.page = action?.payload?.page;
      state.search = action?.payload?.search;
    },
    clearTab: (state) => {
      state.selectedTabIndex = undefined;
      state.page = undefined;
    },
  },
});

export const { setTab, clearTab } = imsOrdersSlice.actions;

export default imsOrdersSlice.reducer;
