const FORM_DATEPICKER_FIELDS = [
  {
    label: "In-Production Date",
    key: "inProductionDate",
  },
  {
    label: "Requested Ship Date",
    key: "requestedShipDate",
  },
  {
    label: "Requested In-Hand Date",
    key: "requestedInHandDate",
  },
  {
    label: "Expected Ship Date",
    key: "expectedShipDate",
  },
];

export default FORM_DATEPICKER_FIELDS;
