import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import Button from "components/LoadingButton";
import Snackbar from "components/SnackBar";
import OrderComments from "components/OrderCard/OrderComments";
import Checkbox from "components/Checkbox";
import OrderCardDialogTitle from "components/OrderCard/OrderCommentDialogTitle";
import { ORDERS_CHECK_IN } from "constants/services";
import {
  StyledAddCommentRow,
  CheckedInWrapper,
  CheckedInData,
  CheckedInForm,
} from "styles/components/order-card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const OrderCheckInDialog = ({ open, setOpen, order, refresh }) => {
  const [state, setState] = useState({
    partiallyStockReceived: false,
    completeStockReceived: false,
    comment: undefined,
  });
  const { mutateAsync } = usePost();

  const { refetch: refetchCheckIns } = useFetch(
    ORDERS_CHECK_IN,
    `${ORDERS_CHECK_IN}/${order?.vendorName}/${order?.orderNumber}`,
    {
      enabled: false,
    }
  );

  const fetchCheckIns = async () => {
    const { data } = await refetchCheckIns();
    if (data) {
      setState({ ...state, checkins: data });
    }
  };

  const addCheckIn = async () => {
    const { data } = await mutateAsync({
      url: `${ORDERS_CHECK_IN}/${order?.vendorName}/${order?.orderNumber}`,
      data: {
        comment: state?.comment,
        stockStatus: state?.partiallyStockReceived ? "Partial" : "Complete",
      },
    });

    if (data) {
      setState({
        ...state,
        checkins: data,
        snackbarMessageSeverity: "success",
        snackbarMessage: "Check-in added successfully !",
      });
      refresh();
    }
  };

  useState(() => {
    if (!state?.comments) fetchCheckIns();
  }, [state]);

  const isCompleteStockReceived = !!state?.checkins?.find(
    (c) => c?.stockStatus === "Complete"
  );

  return (
    <BootstrapDialog onClose={setOpen} open={open} fullWidth maxWidth="md">
      <OrderCardDialogTitle onClose={setOpen}>
        Order Check-in
      </OrderCardDialogTitle>
      <DialogContent dividers>
        <CheckedInWrapper>
          <CheckedInForm>
            <div>
              <Checkbox
                disabled={isCompleteStockReceived}
                label="Partially Stock Received"
                checked={state?.partiallyStockReceived}
                onChange={(partiallyStockReceived) =>
                  setState({
                    ...state,
                    partiallyStockReceived,
                    completeStockReceived: false,
                    comment: "",
                  })
                }
              />
              <Checkbox
                disabled={isCompleteStockReceived}
                label="Complete Stock Received"
                checked={state?.completeStockReceived}
                onChange={(completeStockReceived) =>
                  setState({
                    ...state,
                    completeStockReceived,
                    partiallyStockReceived: false,
                    comment: "Complete Stock Received",
                  })
                }
              />
            </div>
            <StyledAddCommentRow
              style={{ display: "flex", flexDirection: "column", gap: "0px" }}
            >
              <TextareaAutosize
                placeholder="Provide remaining stock information"
                minRows={7}
                disabled={
                  !state?.partiallyStockReceived || isCompleteStockReceived
                }
                value={state?.comment}
                onChange={(e) =>
                  setState({ ...state, comment: e?.target?.value })
                }
              />
              <p
                style={{
                  margin: 0,
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Note: This information will sent to the customer
              </p>
            </StyledAddCommentRow>
            <Button
              label="Submit"
              variant="contained"
              disabled={
                isCompleteStockReceived ||
                (state?.partiallyStockReceived
                  ? !state?.comment?.trim()
                  : !state?.completeStockReceived)
              }
              onClick={addCheckIn}
            />
          </CheckedInForm>
          <CheckedInData>
            <h3 className="title">Previous Check-Ins</h3>
            <OrderComments comments={state?.checkins} />
          </CheckedInData>
        </CheckedInWrapper>
      </DialogContent>

      {!!state?.snackbarMessage && (
        <Snackbar
          message={state?.snackbarMessage}
          onClose={() =>
            setState({
              ...state,
              snackbarMessage: null,
              snackbarMessageSeverity: null,
            })
          }
          severity={state?.snackbarMessageSeverity}
        />
      )}
    </BootstrapDialog>
  );
};

OrderCheckInDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
  }),
};

OrderCheckInDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  order: { orderNumber: "", vendorName: "" },
  refresh: () => {},
};

export default OrderCheckInDialog;
