import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ORDER } from "constants/routes";
import {
  Container,
  StyledTitle,
  StyledAddCircleIcon,
  Icons,
  StyledButton,
} from "styles/components/page-title";

const PageTitle = ({ title, createOrder, buttons }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <StyledTitle variant="h5">{title}</StyledTitle>

      <Icons>
        {createOrder && <StyledAddCircleIcon onClick={() => navigate(ORDER)} />}

        {buttons?.map((b) => {
          const label = b?.label;
          return (
            <StyledButton
              key={label}
              label={label}
              variant="contained"
              onClick={b?.onClick}
            />
          );
        })}
      </Icons>
    </Container>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  createOrder: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

PageTitle.defaultProps = {
  title: "",
  createOrder: false,
  buttons: undefined,
};

export default PageTitle;
