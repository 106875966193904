import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  heading: { paddingVertical: 20, fontSize: 20, paddingHorizontal: 15 },
  subTitle: { fontSize: 10, paddingBottom: 2 },
  title: { fontWeight: "bold", fontSize: 14, paddingBottom: 5 },

  tableHead: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 15,
  },
  tableHeadText: {
    backgroundColor: "#a1a1a1",
    padding: 10,
    fontSize: 10,
    fontWeight: "bold",
    width: "15%",
    textAlign: "center",
  },
  tableHeadDescriptionText: {
    backgroundColor: "#a1a1a1",
    padding: 10,
    fontSize: 10,
    fontWeight: "bold",
    width: "40%",
  },
  tableBody: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 15,
  },
  tableBodyText: {
    padding: 10,
    fontSize: 8,
    fontWeight: "bold",
    width: "15%",
    textAlign: "center",
  },

  descriptionText: {
    fontSize: 8,
    fontWeight: "bold",
    paddingLeft: 10,
  },
  addiotnalInformationText: {
    paddingBottom: 10,
    paddingLeft: 10,
    fontSize: 8,
    fontWeight: "bold",
    width: "100%",
    borderBottom: "1px dashed #ccc",
  },
  tableBodyDescriptionText: {
    padding: 10,
    fontSize: 8,
    fontWeight: "bold",
    width: "40%",
  },
  shipToWrapper: { marginBottom: 20, paddingHorizontal: 15 },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    paddingTop: 15,
  },

  paidTo: {
    paddingBottom: 15,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: 150,
  },
  footer: {
    marginTop: 10,
    borderTop: "1px solid black",
    paddingTop: 10,
    fontSize: 12,
    paddingHorizontal: 15,
    textAlign: "center",
  },
  totalCostWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingHorizontal: 15,
    alignItems: "center",
    marginBottom: 10,
  },

  deductionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingHorizontal: 15,
  },
  totalCost: {
    fontSize: 10,
    paddingRight: 5,
  },
  devider: {
    borderTop: "1px solid black",
    marginBottom: 10,
  },
});

export default styles;
