import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import Button from "components/LoadingButton";
import Snackbar from "components/SnackBar";
import OrderComments from "components/OrderCard/OrderComments";
import OrderCardDialogTitle from "components/OrderCard/OrderCommentDialogTitle";
import { COMMENTS } from "constants/services";
import { StyledAddCommentRow } from "styles/components/order-card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const OrderCommentsDialog = ({ open, setOpen, order, refresh }) => {
  const [state, setState] = useState({});
  const { mutateAsync } = usePost();

  const { refetch: refetchComments } = useFetch(
    COMMENTS,
    `${COMMENTS}/${order?.vendorName}/${order?.orderNumber}`,
    {
      enabled: false,
    }
  );

  const fetchComments = async () => {
    const { data } = await refetchComments();
    if (data) {
      setState({ ...state, comments: data });
    }
  };

  const addComent = async () => {
    const { data } = await mutateAsync({
      url: `${COMMENTS}/${order?.vendorName}/${order?.orderNumber}`,
      data: { comment: state?.comment },
    });

    if (data) {
      setState({
        ...state,
        comments: data,
        snackbarMessageSeverity: "success",
        snackbarMessage: "Comment added successfully !",
      });
      refresh();
    }
  };

  useState(() => {
    if (!state?.comments) fetchComments();
  }, [state]);

  return (
    <BootstrapDialog onClose={setOpen} open={open} fullWidth>
      <OrderCardDialogTitle onClose={setOpen}>
        Order Internal Team Comments
      </OrderCardDialogTitle>
      <DialogContent dividers>
        <OrderComments comments={state?.comments} />

        <StyledAddCommentRow>
          <TextareaAutosize
            placeholder="Comment"
            minRows={3}
            value={state?.comment}
            onKeyDown={(e) => {
              if (e?.key === "Enter" && !!state?.comment) {
                addComent();
              }
            }}
            onChange={(e) => setState({ ...state, comment: e?.target?.value })}
          />
          <Button
            label="Submit"
            variant="contained"
            disabled={!state?.comment?.trim()}
            onClick={addComent}
          />
        </StyledAddCommentRow>
      </DialogContent>

      {!!state?.snackbarMessage && (
        <Snackbar
          message={state?.snackbarMessage}
          onClose={() =>
            setState({
              ...state,
              snackbarMessage: null,
              snackbarMessageSeverity: null,
            })
          }
          severity={state?.snackbarMessageSeverity}
        />
      )}
    </BootstrapDialog>
  );
};

OrderCommentsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
  }),
};

OrderCommentsDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  order: { orderNumber: "", vendorName: "" },
  refresh: () => {},
};

export default OrderCommentsDialog;
