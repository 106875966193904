import { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "components/Autocomplete";
import OrderCardDialogTitle from "components/OrderCard/OrderCommentDialogTitle";
import { TABS_TITLES } from "pages/orders/data";

const MoveOrderDialog = ({ open, onClose, orderNumber, onMove }) => {
  const [state, setState] = useState();

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <OrderCardDialogTitle onClose={onClose}>
        Move Order ({orderNumber})
      </OrderCardDialogTitle>
      <DialogContent dividers>
        <Autocomplete
          label="Status"
          value={state?.status}
          setValue={(c) => setState({ ...state, status: c })}
          inputValue={state?.statusInputValue}
          setInputValue={(v) => setState({ ...state, statusInputValue: v })}
          options={TABS_TITLES?.map((t) => ({ id: t?.value, label: t?.title }))}
        />
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!state?.status}
          onClick={() => onMove(state)}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MoveOrderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  orderNumber: PropTypes.string,
  onMove: PropTypes.func,
};

MoveOrderDialog.defaultProps = {
  open: false,
  onClose: () => {},
  orderNumber: "",
  onMove: () => {},
};

export default MoveOrderDialog;
