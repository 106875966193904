import { useState, useEffect } from "react";
import Tabs from "components/Tabs";
import Badge from "@mui/material/Badge";
import PageTitle from "components/PageTitle";
import Pagination from "components/Pagination";
import OrderRequestsTable from "pages/order-request/OrderRequestsTable";
import OrderRequestRepliesDialog from "pages/order-request/OrderRequestRepliesDialog";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_CUSTOMER_REQUEST } from "constants/services";
import { DEFAULT_STATE, PROP_TYPES } from "pages/order-request/prop-types";
import { StyledContainer, NoDataFound } from "styles/pages/order-request";

const take = 10;

const REQUEST_STATUS = ["OPEN", "PENDING", "SOLVED"];

const OrderRquestPage = () => {
  const { mutateAsync } = usePost();
  const [state, setState] = useState({
    page: 1,
    tabIndex: 0,
  });
  const orderRequests = state?.orderRequests;

  const fetchOrderRequests = async () => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_CUSTOMER_REQUEST,
      data: {
        take,
        skip: (state.page - 1) * take,
        status: REQUEST_STATUS[state?.tabIndex],
      },
    });
    setState({
      ...state,
      orderRequests: data,
      selectedRequest: undefined,
    });
  };

  useEffect(() => {
    fetchOrderRequests();
  }, [state?.page, state?.tabIndex]);

  return (
    <StyledContainer>
      <PageTitle title="Order Quote Requests" />

      <Tabs
        tabs={REQUEST_STATUS?.map((t, i) => {
          if (i === 0) {
            return (
              <div key={t}>
                {t}&nbsp;
                <Badge
                  badgeContent={state?.orderRequests?.openTotalCount || "0"}
                  sx={{ marginLeft: "10px" }}
                  color="error"
                />
              </div>
            );
          }
          if (i === 1) {
            return (
              <div key={t}>
                {t}&nbsp;
                <Badge
                  badgeContent={state?.orderRequests?.pendingTotalCount || "0"}
                  sx={{ marginLeft: "10px" }}
                  color="info"
                />
              </div>
            );
          }
          return (
            <div key={t}>
              {t}&nbsp;
              <Badge
                badgeContent={state?.orderRequests?.solvedTotalCount || "0"}
                sx={{ marginLeft: "10px" }}
                color="primary"
              />
            </div>
          );
        })}
        onTabChange={(v) => {
          setState({ ...state, page: 1, tabIndex: v });
        }}
        value={state?.tabIndex}
      >
        <div style={{ marginTop: "10px" }}>
          {orderRequests?.data?.length > 0 && (
            <Pagination
              count={Math.ceil(state.orderRequests.totalCount / take)}
              page={state?.page}
              handleChange={(e, v) => setState({ ...state, page: v })}
            />
          )}

          {orderRequests?.data?.length > 0 && (
            <OrderRequestsTable state={state} setState={setState} />
          )}

          {!orderRequests?.data?.length && (
            <NoDataFound>No data found !</NoDataFound>
          )}
        </div>
      </Tabs>

      {state?.selectedRequest && (
        <OrderRequestRepliesDialog
          state={state}
          setState={setState}
          fetchOrderRequests={fetchOrderRequests}
        />
      )}
    </StyledContainer>
  );
};

OrderRquestPage.propTypes = PROP_TYPES;

OrderRquestPage.defaultProps = DEFAULT_STATE;

export default OrderRquestPage;
