import { defined, inProduction, completed, cancelled } from "pages/orders/data";

const getBackgroundColorByStatus = (status) => {
  switch (status) {
    case defined?.value:
      return "#e1a053";
    case "Pending":
      return "#e1a053";
    case inProduction?.value:
      return "#ff8b00";
    case "Processed":
      return "#ff8b00";
    case completed?.value:
      return "#3cad87";
    case cancelled?.value:
      return "red";
    case "Declined":
      return "red";
    default:
      return "#008758";
  }
};

const getBackgroundColorByArtProof = (artProof) => {
  const decision = artProof?.decision;

  switch (decision) {
    case "pending":
      return "#b96a0b";
    case "reviewing":
      return "#b47070";
    case "approved":
      return "green";
    case "declined":
      return "red";
    default:
      return "#e1a053";
  }
};

const getArtProofStatusTitle = (artProof) => {
  const decision = artProof?.decision;

  switch (decision) {
    case "pending":
      return "Review Submission Pending";
    case "reviewing":
      return "Waiting from client";
    case "approved":
      return "ArtProof Approved";
    case "declined":
      return "ArtProof Declined";
    default:
      return "ArtProof Pending";
  }
};

export {
  getBackgroundColorByStatus,
  getBackgroundColorByArtProof,
  getArtProofStatusTitle,
};
