import moment from "moment";
import Button from "@mui/material/Button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Autocomplete from "components/Autocomplete";
import Datepicker from "components/Datepicker";
import { usePost } from "hooks/usePost";
import { propTypes, defaultValue } from "pages/bot-network/types";
import SalesReportPdf from "pages/bot-network/SalesReportDialog/SalesReportPdf";
import { CONFLUENCE_ORDERS } from "constants/services";
import {
  StyledSalesFilterReport,
  StyledSalesFilterDatePicker,
} from "styles/pages/bot-network";

const SalesReportDialogDateFilter = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const user = state?.salesReportDialog;

  const handleChange = (key, value) =>
    setState({
      ...state,
      salesReportDialog: { ...state?.salesReportDialog, [key]: value },
    });

  const disabled =
    !user?.startDate || !user?.endDate || !user?.reportGenerateBy;

  return (
    <StyledSalesFilterReport>
      <StyledSalesFilterDatePicker>
        <Datepicker
          label="Start Date"
          shouldDisableDate={(date) =>
            moment(date).isAfter(moment.unix(user?.endDate))
          }
          value={user?.startDate ? moment.unix(user?.startDate) : null}
          handleChange={(e) => handleChange("startDate", e?.unix())}
        />
      </StyledSalesFilterDatePicker>

      <StyledSalesFilterDatePicker>
        <Datepicker
          label="End Date"
          value={user?.endDate ? moment.unix(user?.endDate) : null}
          handleChange={(e) => handleChange("endDate", e?.unix())}
          shouldDisableDate={(date) =>
            moment(date).isBefore(moment.unix(user?.startDate))
          }
        />
      </StyledSalesFilterDatePicker>

      <StyledSalesFilterDatePicker style={{ width: "220px" }}>
        <Autocomplete
          label="Report Generate By"
          value={user?.reportGenerateBy}
          setValue={(c) => handleChange("reportGenerateBy", c)}
          inputValue={user?.reportGenerateByInputValue}
          setInputValue={(v) => handleChange("reportGenerateByInputValue", v)}
          options={[
            { id: "byCompleteDate", label: "By Complete Date" },
            { id: "bySubmitDate", label: "By Submit Date" },
          ]}
        />
      </StyledSalesFilterDatePicker>

      <Button
        variant="contained"
        disabled={disabled}
        onClick={async () => {
          const payload = {
            report: true,
            companyName: user?.companyName,
            startDate: user?.startDate,
            endDate: user?.endDate,
            reportGenerateBy: user?.reportGenerateBy?.id,
          };

          const { data } = await mutateAsync({
            url: CONFLUENCE_ORDERS,
            data: payload,
          });

          const orders = [];

          for (let i = 0; i < data.length; i += 1) {
            const element = data[i];
            const decoratorData = element?.decoratorData;
            const lineItems = decoratorData?.lineItems;
            const otherCharges = decoratorData?.otherCharges;

            for (let j = 0; j < lineItems.length; j += 1) {
              orders.push({
                orderNumber: element?.orderNumber,
                description: lineItems[j]?.description,
                qty: lineItems[j]?.qty,
                chargeAmount: lineItems[j]?.chargeAmount,
                size: lineItems[j]?.size,
                color: lineItems[j]?.color,
                decorationInput: lineItems[j]?.decorationInput,
              });
            }

            for (let j = 0; j < otherCharges.length; j += 1) {
              orders.push({
                orderNumber: element?.orderNumber,
                description: otherCharges[j]?.description,
                qty: otherCharges[j]?.qty,
                chargeAmount: otherCharges[j]?.chargeAmount,
                size: otherCharges[j]?.size,
                color: otherCharges[j]?.color,
                decorationInput: otherCharges[j]?.decorationInput,
              });
            }
          }

          setState({
            ...state,
            salesReportDialog: { ...state?.salesReportDialog, orders },
          });
        }}
      >
        Generate Sales Report
      </Button>

      {!!state?.salesReportDialog?.orders && (
        <PDFDownloadLink
          document={<SalesReportPdf state={state} />}
          fileName={`Payment Voucher (${user?.companyName}) [${
            user?.reportGenerateBy?.id === "byCompleteDate"
              ? "Completed"
              : "Submitted"
          }] [${moment.unix(user?.startDate).format("MMM DD, YYYY")} to ${moment
            .unix(user?.endDate)
            .format("MMM DD, YYYY")}].pdf`}
        >
          {({ loading }) =>
            loading ? "Loading document..." : "Generate Payment Voucher"
          }
        </PDFDownloadLink>
      )}
    </StyledSalesFilterReport>
  );
};

SalesReportDialogDateFilter.propTypes = propTypes;

SalesReportDialogDateFilter.defaultProps = defaultValue;

export default SalesReportDialogDateFilter;
