import { useEffect, useState } from "react";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextFieldInput from "components/TextFieldInput";
import Button from "components/LoadingButton";
import TagsAutocomplete from "components/TagsAutocomplete";
import AddQBLineItem from "pages/order/AddQBLineItem";
import StyledTableCell from "pages/order/OrderEstimationLineItemsTableCell";
import OrderEstimationLineItemDeleteConfirmation from "pages/order/OrderEstimationLineItemDeleteConfirmation";
import { QUICK_BOOKS_ITEMS } from "constants/services";
import { usePost } from "hooks/usePost";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import PublishIcon from "@mui/icons-material/Publish";
import { LineItems, LineItemHeader, LineItemFooter } from "styles/pages/order";

const OrderEstimationLineItems = ({ state, setState }) => {
  const [lineItemDeleteIndex, setLineItemDeleteIndex] = useState(null);
  const { mutateAsync } = usePost();
  const estimateData = state?.estimateData;
  const lineItems = estimateData?.lineItems;

  useEffect(() => {
    if (lineItems?.length > 0 && !state?.editedLineItems?.length) {
      setState({
        ...state,
        editedLineItems: _.uniqBy(lineItems, "description.DisplayName"),
      });
    }
  }, [lineItems, state]);

  const handleChange = (e, index, key) => {
    const value = e?.target?.value;
    setState({
      ...state,
      editedLineItems: state?.editedLineItems?.map((l, j) => {
        if (index !== j) return l;
        return {
          ...l,
          [key]: value,
        };
      }),
    });
  };

  if (!state?.editedLineItems?.length) return null;

  return (
    <LineItems>
      <LineItemHeader>
        <h2>Line Items</h2>
      </LineItemHeader>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell width={120}>Color</StyledTableCell>
              <StyledTableCell width={100}>Size</StyledTableCell>
              <StyledTableCell width={40}>Quantity</StyledTableCell>
              <StyledTableCell width={50}>Rate</StyledTableCell>
              <StyledTableCell width={50}>Amount</StyledTableCell>
              <StyledTableCell width={55} />
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.editedLineItems?.map((row, index) => {
              const key = index;
              const queryResponseItem = row?.qbItemInfo?.QueryResponse?.Item;

              return (
                <TableRow
                  key={`editedLineItems_${key}`}
                  sx={{
                    background:
                      queryResponseItem?.length > 0 ? "#bdf5bd" : "#f1b3b3",
                  }}
                >
                  <StyledTableCell>
                    <TagsAutocomplete
                      optionApiKey="DisplayName"
                      apiEndPoint={`${QUICK_BOOKS_ITEMS}?qbCountry=${state?.printProvider?.id}`}
                      value={
                        row?.description
                          ? {
                              title: row?.description?.DisplayName,
                              value: row?.description?.DisplayName,
                            }
                          : { title: "", value: "" }
                      }
                      setValue={async (v) => {
                        let response;

                        if (v?.Id) {
                          const { data } = await mutateAsync({
                            url: `${QUICK_BOOKS_ITEMS}?qbCountry=${state?.printProvider?.id}`,
                            data: { itemId: v?.Id },
                          });
                          response = data;
                        }

                        setState({
                          ...state,
                          editedLineItems: state?.editedLineItems?.map(
                            (l, j) => {
                              if (index !== j) return l;
                              return {
                                ...l,
                                description: v,
                                qbItemInfo: v?.Id
                                  ? {
                                      QueryResponse: { Item: [response?.Item] },
                                    }
                                  : {},
                              };
                            }
                          ),
                        });
                      }}
                      label=""
                      multiple={false}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextFieldInput
                      value={row.color}
                      handleChange={(e) => handleChange(e, index, "color")}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextFieldInput
                      value={row.size}
                      handleChange={(e) => handleChange(e, index, "size")}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextFieldInput
                      value={row.qty}
                      type="number"
                      handleChange={(e) => handleChange(e, index, "qty")}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextFieldInput
                      type="number"
                      value={row.rate || 0}
                      handleChange={(e) => handleChange(e, index, "rate")}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextFieldInput
                      value={String(
                        Number(row?.rate || 0) * Number(row?.qty || 0)
                      )}
                      handleChange={() => {}}
                      disabled
                    />
                  </StyledTableCell>

                  <StyledTableCell sx={{ textAlign: "center" }}>
                    {state?.editedLineItems?.length > 1 && (
                      <Tooltip arrow title="Delete Line Item">
                        <RemoveCircleIcon
                          sx={{
                            cursor: "pointer",
                            color: "red",
                            marginRight: "5px",
                          }}
                          onClick={() => {
                            setLineItemDeleteIndex(index);
                          }}
                        />
                      </Tooltip>
                    )}
                    {!queryResponseItem?.length && (
                      <Tooltip arrow title="Add to QuickBooks">
                        <PublishIcon
                          sx={{ color: "black", cursor: "pointer" }}
                          onClick={() =>
                            setState({
                              ...state,
                              showQBLineItemDialog: {
                                visibility: true,
                                lineItemIndex: index,
                                payload: {
                                  Name: state?.editedLineItems[index]
                                    ?.description?.DisplayName,
                                  UnitPrice: 0,
                                },
                              },
                            })
                          }
                        />
                      </Tooltip>
                    )}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <LineItemFooter>
        <Button
          label="Add New Line Item"
          variant="contained"
          onClick={() =>
            setState({
              ...state,
              editedLineItems: [...state.editedLineItems, {}],
            })
          }
        />
      </LineItemFooter>

      {state?.showQBLineItemDialog?.visibility && (
        <AddQBLineItem state={state} setState={setState} />
      )}

      {lineItemDeleteIndex !== null && (
        <OrderEstimationLineItemDeleteConfirmation
          open={lineItemDeleteIndex !== null}
          setOpen={() => setLineItemDeleteIndex(null)}
          agree={() => {
            setState({
              ...state,
              editedLineItems: state?.editedLineItems?.filter(
                (l, j) => lineItemDeleteIndex !== j
              ),
            });
            setLineItemDeleteIndex(null);
          }}
        />
      )}
    </LineItems>
  );
};

OrderEstimationLineItems.propTypes = PROP_TYPES;

OrderEstimationLineItems.defaultProps = DEFAULT_STATE;

export default OrderEstimationLineItems;
