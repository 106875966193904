import styled from "styled-components";
// import { PDFViewer } from "@react-pdf/renderer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Stack from "@mui/material/Stack";

const getCardBackgroundColor = (status) => {
  switch (status) {
    case "Approved":
      return "#00800059";
    case "Declined":
      return "#ff00004a";
    default:
      return "#ffa50045";
  }
};

export const StyledEditIcon = styled(EditIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: -12px;
    right: -9px;
    font-size: 13px;
    background-color: #000;
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
`;

export const StyledPictureAsPdfIcon = styled(PictureAsPdfIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: -12px;
    right: 48px;
    font-size: 13px;
    background-color: #000;
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
`;

export const StyledAccountCircleIcon = styled(PeopleIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: -12px;
    right: 18px;
    font-size: 13px;
    background-color: #000;
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
`;

export const StyledContainer = styled(Container)``;

export const Wrapper = styled.div``;

export const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  width: 100%;
  cursor: pointer;

  &.MuiPaper-root {
    background-color: ${({ status }) => getCardBackgroundColor(status)};
  }

  :hover {
    background-color: #f4f4f4;
  }
`;

export const StyledAvatar = styled(Avatar)``;

export const StyledEmail = styled.h5`
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCompanyName = styled.h6`
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
  color: #4a4040;
`;

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  color: #000;
  margin-bottom: 10px;
  div:nth-child(1) {
    font-weight: bold;
  }
`;

export const StyledStatusHeading = styled.div`
  border-bottom: 1px solid black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

export const StyledUserPassword = styled.div`
  margin-top: 10px;
`;

export const StyledIframe = styled.iframe`
  height: 100%;
`;

export const StyledUserCred = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  p {
    margin: 0;
    line-height: 22px;
    font-size: 13px;
  }
  b {
    font-size: 12px;
  }
`;

export const OrdersWrapper = styled.div`
  margin: 20px;
`;

export const StyledPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledStack = styled(Stack)`
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

export const StyledSalesFilterReport = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  justify-content: flex-end;
  align-items: center;

  a {
    background: black;
    color: white;
    text-decoration: none;
    padding: 6px 16px;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: 0.02857em;
  }
`;

export const StyledSalesFilterDatePicker = styled.div`
  width: 160px;
`;

export const SalesReportDialogWrapper = styled.div`
  padding: 15px;
`;

// export const StyledPDFViewer = styled(PDFViewer)`
//   width: 100%;
//   height: 800px;
// `;

export const SalesReportTableInput = styled.div`
  width: 70px;
  float: right;
`;

export const SalesReportTableTextarea = styled.div`
  width: 100%;

  textarea {
    width: 100%;
  }
`;
