import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "pages/decorations/Header";
import Decorations from "pages/decorations/Decorations";
import { useFetch } from "hooks/useFetch";
import { DEVELOPER_ROLE } from "constants/roles";
import { CONFLUENCE_DECORATIONS } from "constants/services";
import { StyledContainer } from "styles/pages/decorations";

const Decoration = () => {
  const { refetch } = useFetch(CONFLUENCE_DECORATIONS, CONFLUENCE_DECORATIONS, {
    enabled: false,
  });
  const [state, setState] = useState();
  const user = useSelector((s) => s?.user);

  const fetchDecorations = async () => {
    const { data } = await refetch();
    if (data) setState({ ...state, decorations: data });
  };

  useEffect(() => {
    if (!state?.decorations) fetchDecorations();
  }, [state?.decorations]);

  if (user?.role !== DEVELOPER_ROLE.role) {
    return null;
  }

  return (
    <StyledContainer fixed>
      <Header state={state} setState={setState} />
      <Decorations state={state} setState={setState} />
    </StyledContainer>
  );
};

export default Decoration;
