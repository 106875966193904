import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: null,
  profileImg: null,
  pin: null,
  role: null,
  roleName: null,
  email: null,
  authToken: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.fullName = action?.payload?.fullName;
      state.profileImg = action?.payload?.profileImg;
      state.pin = action?.payload?.pin;
      state.role = action?.payload?.role;
      state.roleName = action?.payload?.roleName;
      state.email = action?.payload?.email;
      state.authToken = action?.payload?.authToken;
    },
    logout: (state) => {
      state.fullName = initialState?.fullName;
      state.profileImg = initialState?.profileImg;
      state.pin = initialState?.pin;
      state.role = initialState?.role;
      state.roleName = initialState?.roleName;
      state.email = initialState?.email;
      state.authToken = initialState?.authToken;
    },
  },
});

export const { setUser, logout } = userSlice.actions;

export default userSlice.reducer;
