import { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import OrderDetailDialog from "components/IMSOrderCard/OrderDetailDialog";
import USAFlag from "assets/images/usa-flag.png";
import IMSLogo from "assets/images/ims-logo.png";
import {
  Container,
  Body,
  Wrapper,
  Status,
  Header,
  PONumber,
  VendorName,
  UploadDate,
  InProductionDate,
  ShippedDate,
  VendorLogo,
  DecorationChips,
  DecorationChip,
  Flag,
} from "styles/components/order-card";

const OrderCard = ({ data }) => {
  const [state, setState] = useState();
  const shippedEvent = data?.events?.find((e) => e?.action === "shipped");

  const toggleOrderDetailDialogVisibility = () =>
    setState({ ...state, orderDetailDialog: !state?.orderDetailDialog });

  return (
    <Container>
      <Body
        status={data?.status}
        onClick={() => toggleOrderDetailDialogVisibility()}
      >
        <VendorLogo src={IMSLogo} style={{ backgroundSize: "56px" }}>
          <Flag src={USAFlag} alt="print-provider" />
        </VendorLogo>
        <Wrapper>
          <Header>
            <div>
              <PONumber>{data?.cart?.header[0].sessionid[0]}</PONumber>
              <VendorName>IMS Retail</VendorName>
            </div>

            <Tooltip title="jackie.jegen@imsretail.com" arrow>
              <Avatar
                sx={{
                  bgcolor: "#a1a1a1",
                  width: 32,
                  height: 32,
                  fontSize: 12,
                  border: "1px solid #a1a1a1",
                }}
              />
            </Tooltip>
          </Header>
          <div>
            <Status status={data?.status}>{data?.status}</Status>
          </div>
          <UploadDate>
            Created Date :&nbsp;
            {
              data?.events
                ?.find((e) => e?.action === "created")
                ?.time?.split("T")[0]
            }
          </UploadDate>
          {data?.events?.find((e) => e?.action === "processed") && (
            <InProductionDate>
              Production Date :&nbsp;
              {
                data?.events
                  ?.find((e) => e?.action === "processed")
                  ?.time?.split("T")[0]
              }
            </InProductionDate>
          )}
          {shippedEvent && (
            <ShippedDate>
              Shipped Date :&nbsp;
              {
                data?.events
                  ?.find((e) => e?.action === "shipped")
                  ?.time?.split("T")[0]
              }
            </ShippedDate>
          )}
          {data?.events?.find((e) => e?.action === "declined") && (
            <ShippedDate>
              Declined Date :&nbsp;
              {
                data?.events
                  ?.find((e) => e?.action === "declined")
                  ?.time?.split("T")[0]
              }
            </ShippedDate>
          )}

          <DecorationChips style={{ paddingBottom: "10px" }}>
            <Tooltip title="EMBROIDERY" arrow>
              <DecorationChip>EMB</DecorationChip>
            </Tooltip>
          </DecorationChips>
        </Wrapper>
      </Body>

      {state?.orderDetailDialog && (
        <OrderDetailDialog
          open
          data={data}
          setOpen={toggleOrderDetailDialogVisibility}
        />
      )}
    </Container>
  );
};

OrderCard.propTypes = {
  data: PropTypes.shape({
    cart: PropTypes.shape({
      header: PropTypes.arrayOf(
        PropTypes.shape({ sessionid: PropTypes.arrayOf(PropTypes.string) })
      ),
    }),
    status: PropTypes.string,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        time: PropTypes.string,
      })
    ),
  }),
};

OrderCard.defaultProps = {
  data: {},
};

export default OrderCard;
