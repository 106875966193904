import styled from "styled-components";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const Wrapper = styled.div`
  margin: 10px 0;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const PageTitle = styled.h2`
  margin: 0;
`;

export const Decorations = styled.div`
  width: 300px;
`;
