import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import openCloudinaryWidget from "utils/cloudinary";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CreateIcon from "@mui/icons-material/Create";
import { UploadPO, UploadPOContainer } from "styles/pages/order";

const UploadDocuments = ({
  disabled,
  path,
  onChange,
  url,
  fileName,
  label,
  tooltipTitle,
  onUpdate,
}) => (
  <UploadPOContainer>
    <UploadPO
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          openCloudinaryWidget(path, ["pdf"], (v) => onChange(v));
        }
      }}
    >
      {url ? (
        <PictureAsPdfIcon className="pdf" />
      ) : (
        <CloudUploadIcon className="pdf" />
      )}
      {!url && <h3>{label}</h3>}
      {url && <p>{fileName}</p>}
    </UploadPO>
    {url && (
      <Tooltip title={`Download ${tooltipTitle}`} arrow>
        <DownloadIcon className="download" onClick={() => window.open(url)} />
      </Tooltip>
    )}

    {url && (
      <Tooltip title="Update File" arrow>
        <CreateIcon
          className="edit"
          onClick={() =>
            openCloudinaryWidget(path, ["pdf"], (v) => onUpdate(v))
          }
        />
      </Tooltip>
    )}
  </UploadPOContainer>
);

UploadDocuments.propTypes = {
  disabled: PropTypes.bool,
  path: PropTypes.string,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  url: PropTypes.string,
  fileName: PropTypes.string,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

UploadDocuments.defaultProps = {
  disabled: false,
  path: "",
  onChange: () => {},
  onUpdate: () => {},
  url: "",
  fileName: "",
  label: "",
  tooltipTitle: "",
};

export default UploadDocuments;
