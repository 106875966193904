import { propTypes, defaultProps } from "components/IMSOrderCard/types";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Title, Note } from "styles/components/order-card";

const OrderNotes = () => (
  <div>
    <div style={{ marginBottom: "10px" }}>
      <Title>Notes</Title>
      <TextareaAutosize
        style={{ width: "98%", padding: "1%" }}
        placeholder="Notes"
        minRows={8}
        // value={state?.attentionMessage}
        // onChange={(e) =>
        //   setState({ ...state, attentionMessage: e?.target?.value })
        // }
      />
    </div>

    <div>
      <Title>Prev Notes</Title>
      <Note>
        <div className="note">Test</div>
        <div className="time">Test</div>
      </Note>
      <Note>
        <div className="note">Test</div>
        <div className="time">Test</div>
      </Note>
    </div>
  </div>
);

OrderNotes.propTypes = propTypes;

OrderNotes.defaultProps = defaultProps;

export default OrderNotes;
