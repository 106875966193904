import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Transition from "components/FullScreenDialogTransition";
import SalesReportDialogDateFilter from "pages/bot-network/SalesReportDialog/SalesReportDialogDateFilter";
import SalesReportOrders from "pages/bot-network/SalesReportDialog/SalesReportOrders";
// import SalesReportPdf from "pages/bot-network/SalesReportDialog/SalesReportPdf";
import { propTypes, defaultValue } from "pages/bot-network/types";
import { SalesReportDialogWrapper } from "styles/pages/bot-network";

const SalesReportDialog = ({ state, setState }) => {
  const user = state?.salesReportDialog;
  const open = !!user;
  const handleClose = () =>
    setState({ ...state, salesReportDialog: undefined });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Sales Report ({user?.companyName})
          </Typography>
        </Toolbar>
      </AppBar>

      <SalesReportDialogWrapper>
        <SalesReportDialogDateFilter state={state} setState={setState} />
        {/* {!!state?.salesReportDialog?.orders && (
          <SalesReportPdf state={state} setState={setState} />
        )} */}

        {!!state?.salesReportDialog?.orders && (
          <SalesReportOrders state={state} setState={setState} />
        )}
      </SalesReportDialogWrapper>
    </Dialog>
  );
};

SalesReportDialog.propTypes = propTypes;

SalesReportDialog.defaultProps = defaultValue;

export default SalesReportDialog;
