import { useEffect, useState } from "react";
import _ from "lodash";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Checkbox from "components/Checkbox";
import { useFetch } from "hooks/useFetch";
import { BOT_NETWORK_USERS } from "constants/services";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  StyledDecoratorRow,
  StyledDecorations,
  StyledNote,
  StyledAddress,
  DecorationTitle,
  DecorationImage,
  StyledAccordion,
  StyledChips,
} from "styles/pages/order";

const SubmitToDecoratorDialog = ({ state, setState }) => {
  const [users, setUsers] = useState();
  const { refetch: usersRefetch } = useFetch(
    BOT_NETWORK_USERS,
    BOT_NETWORK_USERS,
    {
      enabled: false,
    }
  );

  const fetchUsers = async () => {
    const { data } = await usersRefetch();
    const filteredUsers = data?.filter(
      (d) =>
        d?.status === "Approved" &&
        d?.decorations?.filter(
          (e) => !!state?.decorations?.find((f) => f?.value === e?.decoCode)
        )
    );
    if (data?.length > 0) {
      let newUsers = filteredUsers?.map((u) => ({
        ...u,
        checked:
          u?.companyName ===
          state?.decoratorData?.selectedDecorator?.companyName,
      }));

      if (state?.submitToDecorator) {
        newUsers = newUsers?.filter((f) => f?.checked);
      }

      newUsers = _.sortBy(newUsers, ["companyName"]);

      setUsers(newUsers);
    }
  };

  const handleClose = () =>
    setState({
      ...state,
      submitToDecoratorDialog: false,
    });

  useEffect(() => {
    if (!users) fetchUsers();
  }, [users]);

  const selectdUser = users?.find((u) => u?.checked);
  const missingDecorations = selectdUser
    ? state?.decorations?.filter(
        (d) => !selectdUser?.decorations?.find((e) => e?.decoCode === d?.value)
      )
    : false;

  const renderThreadColors = (threadColors) => {
    if (threadColors?.length > 0) {
      return (
        <StyledChips>
          <div>Thread Colors: </div>
          {threadColors?.map((t) => (
            <Chip label={t} key={t} />
          ))}
        </StyledChips>
      );
    }

    return (
      <Typography
        sx={{ textAlign: "center", fontSize: "12px", color: "#a1a1a1" }}
      >
        No Information provided by Decorator
      </Typography>
    );
  };

  const renderScreenPrintOptons = (decoration) => {
    const screens = decoration?.numberOfScreens;
    if (screens) {
      return <Typography>Screens: {screens}</Typography>;
    }

    return <Typography>No Information provided by Decorator</Typography>;
  };

  return (
    <Dialog
      open={state?.submitToDecoratorDialog}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Select Decorator</DialogTitle>
      <DialogContent dividers>
        {users?.map((u, i) => (
          <StyledDecoratorRow key={`${u?.companyName} (${u?.email})`}>
            <Checkbox
              label={
                <>
                  <b>{`${u?.companyName}`}</b>
                  <br />
                  <StyledAddress>{`${u?.city}, ${u?.state}, ${u?.zip}`}</StyledAddress>
                </>
              }
              checked={u?.checked}
              onChange={(v) =>
                setUsers(
                  users?.map((j, k) => ({
                    ...j,
                    checked: i !== k ? false : v,
                  }))
                )
              }
            />

            {!!u?.decorations?.length && (
              <StyledDecorations>
                {u?.decorations?.map((d) => {
                  const isScreenPrint = d?.decoFullName === "Screen Print";
                  const isEmbroidery = d?.decoFullName === "Embroidery";
                  const disabled = !isEmbroidery && !isScreenPrint;
                  return (
                    <StyledAccordion key={d?.decoCode} disabled={disabled}>
                      <AccordionSummary
                        expandIcon={disabled ? null : <ExpandMoreIcon />}
                      >
                        <DecorationTitle>
                          <DecorationImage src={d?.iconUrl} />
                          {d?.decoCode}
                        </DecorationTitle>
                      </AccordionSummary>
                      <AccordionDetails>
                        {isEmbroidery && renderThreadColors(d?.threadColors)}
                        {isScreenPrint && renderScreenPrintOptons(d)}
                      </AccordionDetails>
                    </StyledAccordion>
                  );
                })}
              </StyledDecorations>
            )}
          </StyledDecoratorRow>
        ))}

        {missingDecorations?.length > 0 && (
          <StyledNote>
            This decorator does not have ability to produce&nbsp;
            <b>{missingDecorations?.map((m) => m?.value)?.join(", ")}</b>&nbsp;
            decoration(s).
          </StyledNote>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!selectdUser}
          onClick={() =>
            setState({
              ...state,
              submitToDecoratorDialog: false,
              selectedDecorator: selectdUser,
            })
          }
          variant="contained"
        >
          {state?.submitToDecorator ? "Update Work Order" : "Create Work Order"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SubmitToDecoratorDialog.propTypes = PROP_TYPES;

SubmitToDecoratorDialog.defaultProps = DEFAULT_STATE;

export default SubmitToDecoratorDialog;
