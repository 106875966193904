import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Pagination from "components/Pagination";
import UserDialog from "pages/vendors/users/UserDialog";
import DeleteConfirmDialog from "pages/vendors/users/DeleteConfirmDialog";
import { usePost } from "hooks/usePost";
import { VENDOR_USERS } from "constants/services";
import { VENDORS } from "constants/routes";
import {
  StyledContainer,
  Header,
  Title,
  StyledPagination,
  Users,
  Actions,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledAddIcon,
} from "styles/pages/vendors-users";

const take = 10;

const VendorsUsers = () => {
  const { mutateAsync } = usePost();
  const [users, setUsers] = useState();
  const [page, setPage] = useState(1);
  const { vendorName } = useParams();
  const [user, setUser] = useState();
  const [deleteUser, setDeleteUser] = useState();

  const fetchUsers = async () => {
    const { data } = await mutateAsync({
      url: `${VENDOR_USERS}/${vendorName}`,
      data: { take, skip: (page - 1) * take },
    });
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, [page]);

  const refresh = () => {
    if (page === 1) {
      fetchUsers();
    } else {
      setPage(1);
    }
  };

  return (
    <StyledContainer fixed>
      <Breadcrumbs sx={{ marginBottom: "10px" }}>
        <Link underline="hover" color="inherit" to={VENDORS}>
          Vendors
        </Link>
        <Typography color="text.primary">Vendors Users</Typography>
      </Breadcrumbs>

      <Header>
        <Title>Vendors Users ({vendorName})</Title>
        <Tooltip title="Add User">
          <StyledAddIcon
            onClick={() => setUser({ create: true, vendorName })}
          />
        </Tooltip>
      </Header>

      <StyledPagination>
        {users?.data?.length > 0 && (
          <Pagination
            count={Math.ceil(users.totalCount / take)}
            page={page}
            handleChange={(e, v) => setPage(v)}
          />
        )}
      </StyledPagination>

      <Users>
        <List>
          {users?.data?.map((u) => (
            <div key={u?.clientEmail}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={u?.clientEmail?.split("@")[0]?.toUpperCase()}
                    src={`/static/images/avatar/${u?.clientEmail
                      ?.split("@")[0]
                      ?.toUpperCase()}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={u?.clientEmail?.split("@")[0]}
                  secondary={
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="grey"
                    >
                      {u?.clientEmail}
                    </Typography>
                  }
                />
                <Actions>
                  <StyledEditIcon
                    onClick={() => setUser({ ...u, vendorName })}
                  />
                  <StyledDeleteIcon onClick={() => setDeleteUser(u)} />
                </Actions>
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          ))}
        </List>
      </Users>

      {!!user && (
        <UserDialog
          open={!!user}
          hide={() => setUser(undefined)}
          data={user}
          refresh={() => {
            setUser(undefined);
            refresh();
          }}
        />
      )}

      {!!deleteUser && (
        <DeleteConfirmDialog
          open={!!deleteUser}
          onCancel={() => setDeleteUser(undefined)}
          onDelete={async () => {
            setDeleteUser(undefined);
            await mutateAsync({
              url: `${VENDOR_USERS}/${vendorName}/${deleteUser?.clientEmail}`,
              isDelete: true,
            });
            refresh();
          }}
        />
      )}
    </StyledContainer>
  );
};

export default VendorsUsers;
