import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DecoratorWorkOrderShipTo from "pages/order/DecoratorWorkOrderShipTo";
import DecoratorWorkOrderGarmentData from "pages/order/DecoratorWorkOrderGarmentData";
import DecoratorWorkOrderLineItems from "pages/order/DecoratorWorkOrderLineItems";
import DecoratorOtherCharges from "pages/order/DecoratorOtherCharges";
import { usePost } from "hooks/usePost";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { BOT_CA_ADDRESS, BOT_USA_ADDRESS } from "constants/address";
import { CONFLUENCE_ORDERS } from "constants/services";
import {
  DecoratortWorkDialogWrapper,
  PoSecureUrl,
  WorkOrderWrapper,
  WorkOrderHeader,
  WorkOrderTitle,
  SubmitButton,
} from "styles/pages/order";

const DecoratorWorkOrderDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const [decoratorData, setDecoratorData] = useState();
  const estimateData = state?.estimateData;
  const shipAddress = estimateData?.shipAddress;
  const printProvider = state?.printProvider?.id;
  const address = printProvider === "CA" ? BOT_CA_ADDRESS : BOT_USA_ADDRESS;
  const selectedDecorator = state?.selectedDecorator;
  const handleClose = () => {
    setState({ ...state, selectedDecorator: undefined });
  };

  useEffect(() => {
    if (!decoratorData) {
      if (!state?.decoratorData) {
        setDecoratorData({
          selectedDecorator,
          shipAddress,
          otherCharges: [],
          lineItems: estimateData?.lineItems?.map((l) => {
            const decorationCost =
              state?.decorations[0]?.value === "EMB" ? 9 : 0;
            return {
              ...l,
              chargeAmount:
                state?.decorations[0]?.value === "EMB"
                  ? decorationCost * l.qty
                  : 0,
              decorationCost,
              decoration:
                state?.decorations?.length > 1
                  ? undefined
                  : {
                      id: state?.decorations[0]?.value,
                      label: state?.decorations[0]?.value,
                    },
            };
          }),
        });
      } else {
        setDecoratorData(state?.decoratorData);
      }
    }
  }, [decoratorData, state]);

  const submitDisabled =
    !decoratorData?.shipAddress?.shipName?.trim() ||
    !decoratorData?.shipAddress?.address1?.trim() ||
    !decoratorData?.shipAddress?.city?.trim() ||
    !decoratorData?.shipAddress?.state?.trim() ||
    !decoratorData?.shipAddress?.country?.trim() ||
    !decoratorData?.shipAddress?.zip?.trim() ||
    !!decoratorData?.lineItems?.find(
      (l) =>
        !l?.description?.trim() ||
        !l?.qty ||
        !l?.color?.trim() ||
        !l?.size?.trim() ||
        !l?.decoration ||
        !l?.decorationCost
    ) ||
    !!decoratorData?.otherCharges?.find(
      (l) => !l?.description?.trim() || !l?.qty || !l?.unitCost
    );

  return (
    <Dialog fullScreen open={!!selectedDecorator} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Work Order for Decorator ({selectedDecorator?.companyName})
          </Typography>
          <Button
            color="inherit"
            onClick={async () => {
              const payload = {
                vendorName: state?.vendorName,
                orderNumber: state?.orderNumber,
                submitToDecorator: !!state?.submitToDecorator,
                decoratorData: {
                  ...decoratorData,
                  lineItems: decoratorData.lineItems?.map((l) => ({
                    ...l,
                    additionalInformation: l?.additionalInformation || "",
                  })),
                },
              };

              await mutateAsync({
                url: CONFLUENCE_ORDERS,
                data: payload,
                isPatch: true,
              });
            }}
          >
            save
          </Button>
          {!state?.submitToDecorator && (
            <SubmitButton
              disabled={submitDisabled}
              onClick={async () => {
                const payload = {
                  vendorName: state?.vendorName,
                  orderNumber: state?.orderNumber,
                  submitToDecorator: true,
                  decoratorData: {
                    ...decoratorData,
                    lineItems: decoratorData.lineItems?.map((l) => ({
                      ...l,
                      additionalInformation: l?.additionalInformation || "",
                    })),
                  },
                };

                await mutateAsync({
                  url: CONFLUENCE_ORDERS,
                  data: payload,
                  isPatch: true,
                });

                window.location.reload();
              }}
            >
              submit
            </SubmitButton>
          )}
        </Toolbar>
      </AppBar>
      <DecoratortWorkDialogWrapper>
        <PoSecureUrl>
          <iframe src={state?.poSecureUrl} title="po-secure-url" />

          <iframe src={state?.artProof?.artProofUrl} title="po-secure-url" />
        </PoSecureUrl>
        <WorkOrderWrapper>
          <WorkOrderHeader>
            <div className="address">
              <div>{address?.comapnyName}</div>
              <div className="company-address">{address?.addressLineOne}</div>
              <div className="company-location">
                {address?.city} {address?.state} {address?.postalCode}
              </div>
              <div className="company-email">{address?.email}</div>
            </div>
            <div className="logo">
              <img src={address?.companyLogoUrl} alt="logo" />
            </div>
          </WorkOrderHeader>

          <WorkOrderTitle>Work Order</WorkOrderTitle>

          <DecoratorWorkOrderGarmentData
            decoratorData={decoratorData}
            setDecoratorData={setDecoratorData}
          />

          <DecoratorWorkOrderShipTo
            decoratorData={decoratorData}
            setDecoratorData={setDecoratorData}
          />

          <DecoratorWorkOrderLineItems
            decoratorData={decoratorData}
            setDecoratorData={setDecoratorData}
            state={state}
          />

          <DecoratorOtherCharges
            decoratorData={decoratorData}
            setDecoratorData={setDecoratorData}
            state={state}
          />
        </WorkOrderWrapper>
      </DecoratortWorkDialogWrapper>
    </Dialog>
  );
};

DecoratorWorkOrderDialog.propTypes = PROP_TYPES;

DecoratorWorkOrderDialog.defaultProps = DEFAULT_STATE;

export default DecoratorWorkOrderDialog;
