import SettingsIcon from "@mui/icons-material/Settings";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FactoryIcon from "@mui/icons-material/Factory";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IMSLogo from "assets/images/ims-logo.png";
import {
  SETTINGS,
  ORDERS,
  ORDER,
  CALENDAR,
  VENDORS,
  BOT_NETWORK,
  DECORATIONS,
  ORDER_REQUEST,
  IMS_ORDERS,
} from "constants/routes";
import { CSR_ROLE, DEVELOPER_ROLE } from "constants/roles";

const MENUS = [
  {
    title: "Create Order",
    Icon: AddCircleIcon,
    link: ORDER,
  },
  {
    title: "Orders",
    Icon: LocalMallIcon,
    link: ORDERS,
  },
  {
    title: "IMS Orders (SAGE)",
    Icon: () => <img src={IMSLogo} alt="ims" width="24" />,
    link: IMS_ORDERS,
  },
  {
    title: "Calendar",
    Icon: CalendarMonthIcon,
    link: CALENDAR,
  },

  {
    title: "Settings",
    Icon: SettingsIcon,
    link: SETTINGS,
  },
];

const getMenus = (role) => {
  const menus = [...MENUS];

  if (role === DEVELOPER_ROLE.role || role === CSR_ROLE.role) {
    menus.splice(3, 0, {
      title: "Vendors",
      Icon: FactoryIcon,
      link: VENDORS,
    });

    menus.splice(4, 0, {
      title: "Order Request",
      Icon: ListAltIcon,
      link: ORDER_REQUEST,
    });
  }

  if (role === DEVELOPER_ROLE.role) {
    menus.splice(menus.length - 1, 0, {
      title: "Decorations",
      Icon: AutoAwesomeIcon,
      link: DECORATIONS,
    });

    menus.splice(menus.length - 1, 0, {
      title: "BOT Network",
      Icon: SettingsInputComponentIcon,
      link: BOT_NETWORK,
    });
  }

  return menus;
};

export default getMenus;
