import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";

const getContainerSize = (size) => {
  switch (size) {
    case "sm":
      return { width: "50px", height: "50px", fontSize: "25px" };
    case "md":
      return { width: "100px", height: "100px", fontSize: "50px" };
    default:
      return { width: "150px", height: "150px", fontSize: "75px" };
  }
};

export const Container = styled.div`
  width: ${(props) => getContainerSize(props?.size)?.width};
  height: ${(props) => getContainerSize(props?.size)?.height};
  border-radius: 5px;
  padding: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const StyledImage = styled.img`
  width: 80%;
`;

export const StyledFileImage = styled(CloudUploadIcon)`
  font-size: ${(props) => getContainerSize(props?.size)?.fontSize};
`;

export const StyledEditIcon = styled(EditIcon)`
  position: absolute;
  font-size: 12px;
  bottom: -16px;
  right: -16px;
  background: white;
  border-radius: 50%;
  padding: 8px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  :hover {
    background-color: #a1a1a1;
    color: white;
  }
`;
