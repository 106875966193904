import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "components/Autocomplete";
import Datepicker from "components/Datepicker";
import { useFetch } from "hooks/useFetch";
import { CONFLUENCE_VENDORS } from "constants/services";
import { TABS_TITLES } from "pages/orders/data";
import { CreateOrderWrapper, CreateOrderRow } from "styles/pages/orders";

const CreateReportDialog = ({ open, onClose, onCreate }) => {
  const [state, setState] = useState();
  const { refetch: refetchVendors } = useFetch(
    CONFLUENCE_VENDORS,
    CONFLUENCE_VENDORS,
    {
      enabled: false,
    }
  );

  const fetchVendors = async () => {
    const { data } = await refetchVendors();

    if (data) {
      setState({
        ...state,
        vendors: data,
      });
    }
  };

  useEffect(() => {
    if (!state?.vendors) fetchVendors();
  }, [state]);

  const disabled = !state?.vendor || !state?.startDate || !state?.endDate;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Create Report</DialogTitle>

      <DialogContent dividers>
        <CreateOrderWrapper>
          <CreateOrderRow style={{ marginBottom: "20px" }}>
            <Autocomplete
              label="Vendor"
              value={state?.vendor}
              setValue={(c) =>
                setState({
                  ...state,
                  vendor: c,
                })
              }
              inputValue={state?.vendorNameInput}
              setInputValue={(v) =>
                setState({
                  ...state,
                  vendorNameInput: v,
                })
              }
              options={
                state?.vendors?.map((u) => ({
                  ...u,
                  id: u?.name,
                  label: u?.name,
                })) || []
              }
            />

            <Autocomplete
              label="Status"
              value={state?.status}
              setValue={(c) =>
                setState({
                  ...state,
                  status: c,
                })
              }
              inputValue={state?.statusInput}
              setInputValue={(v) =>
                setState({
                  ...state,
                  statusInput: v,
                })
              }
              options={TABS_TITLES?.map((t) => ({
                id: t?.value,
                label: t?.title,
              }))}
            />
          </CreateOrderRow>

          <CreateOrderRow>
            <Datepicker
              label="Start Date"
              shouldDisableDate={(date) =>
                moment(date).isAfter(moment.unix(state?.endDate))
              }
              value={state?.startDate ? moment.unix(state?.startDate) : null}
              handleChange={(e) => setState({ ...state, startDate: e?.unix() })}
            />

            <Datepicker
              label="End Date"
              value={state?.endDate ? moment.unix(state?.endDate) : null}
              handleChange={(e) => setState({ ...state, endDate: e?.unix() })}
              shouldDisableDate={(date) =>
                moment(date).isBefore(moment.unix(state?.startDate))
              }
            />
          </CreateOrderRow>
        </CreateOrderWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const payload = {
              report: true,
              status: state?.status?.id,
              vendorName: state?.vendor?.id,
              startDate: state?.startDate,
              endDate: state?.endDate,
            };
            onCreate(payload);
          }}
          disabled={disabled}
        >
          Create Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateReportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
};

CreateReportDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onCreate: () => {},
};

export default CreateReportDialog;
