export const LOGIN = "/admin/login";
export const SETTINGS = "/settings";
export const ORDERS = "/";
export const ORDER = "/order";
export const CALENDAR = "/calendar";
export const VENDORS = "/vendors";
export const VENDORS_USERS = "/vendors/users";
export const CUSTOMER_TYPE = "/customer-type";
export const BOT_NETWORK = "/bot-network";
export const DECORATIONS = "/decorations";
export const ORDER_REQUEST = "/order-request";
export const IMS_ORDERS = "/ims-orders";
