import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "components/TextFieldInput";
import UploadImage from "components/UploadImage";
import {
  MarkShipped,
  UploadShippedWrapper,
  StyledParcelInformation,
  StyledUploadShippingLabel,
} from "styles/components/order-card";

const UploadShippingLabelDialog = ({ open, setOpen, onSubmit, data }) => {
  const [state, setState] = useState(data?.shippingLabelInformation);
  const handleClose = () => {
    setState();
    setOpen(false);
  };

  useEffect(() => {
    if (!state && data?.shippingLabelInformation)
      setState(data?.shippingLabelInformation);
  }, [state]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Upload Shipping Label</DialogTitle>
      <DialogContent>
        <UploadShippedWrapper>
          <StyledParcelInformation>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parcel Number</TableCell>
                    <TableCell>Length (inches)</TableCell>
                    <TableCell>Width (inches)</TableCell>
                    <TableCell>Height (inches)</TableCell>
                    <TableCell>Weight (.lbs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.shippingParcelInformation.map((row) => (
                    <TableRow
                      key={row.parcelNumber}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.parcelNumber}
                      </TableCell>
                      <TableCell>{row.length}</TableCell>
                      <TableCell>{row.width}</TableCell>
                      <TableCell>{row.height}</TableCell>
                      <TableCell>{row.weight}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledParcelInformation>
          <MarkShipped>
            <StyledUploadShippingLabel>
              <UploadImage
                showPencilIcon={false}
                url={state?.shippingLabelUrl}
                folder="ConfluenceApp/ShippingLabels"
                setUrl={(v) =>
                  setState({
                    ...state,
                    shippingLabelUrl: v?.secure_url,
                  })
                }
              />
              <h5>Upload Shipping Label</h5>
            </StyledUploadShippingLabel>

            <TextField
              label="Tracking Number"
              fullWidth
              value={state?.trackingNumber}
              handleChange={(e) =>
                setState({ ...state, trackingNumber: e?.target?.value })
              }
            />
          </MarkShipped>
        </UploadShippedWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!state?.trackingNumber?.trim() || !state?.shippingLabelUrl}
          onClick={() => {
            onSubmit(state);
            setState();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadShippingLabelDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    shippingParcelInformation: PropTypes.arrayOf(PropTypes.shape({})),
    shippingLabelInformation: PropTypes.shape({
      trackingNumber: PropTypes.string,
    }),
  }),
};

UploadShippingLabelDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  onSubmit: () => {},
  data: { shippingParcelInformation: [], shippingLabelInformation: {} },
};

export default UploadShippingLabelDialog;
