import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const Header = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin: 0;
`;

export const StyledPagination = styled.div`
  margin: 20px 0;
`;

export const Users = styled.div``;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`;

export const StyledAddIcon = styled(AddCircleIcon)`
  cursor: pointer;
`;

export const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 10px 0;
`;
