import PageTitle from "components/PageTitle";
import { propTypes, defaultValue } from "pages/decorations/types";
import { StyledHeader, CreateDecorationButton } from "styles/pages/decorations";

const Header = ({ state, setState }) => (
  <StyledHeader>
    <PageTitle title="Decorations" />
    <CreateDecorationButton
      variant="contained"
      onClick={() =>
        setState({
          ...state,
          selectedDecoration: {
            edit: false,
          },
        })
      }
    >
      Add
    </CreateDecorationButton>
  </StyledHeader>
);

Header.propTypes = propTypes;

Header.defaultProps = defaultValue;

export default Header;
