import { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { propTypes, defaultValue } from "pages/bot-network/types";
import { StyledIframe, StyledUserCred } from "styles/pages/bot-network";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DecoratorAccountDialog = ({ state, setState }) => {
  const open = !!state?.decoratorAccountDialog;
  const handleClose = () =>
    setState({ ...state, decoratorAccountDialog: undefined });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            BOT Network ({state?.decoratorAccountDialog?.companyName})
          </Typography>
        </Toolbar>
      </AppBar>
      <StyledIframe src="https://botnetwork.app/login" title="bot-network" />

      <StyledUserCred>
        <b>Credentials (Only visible to Dev(s))</b>
        <p>{state?.decoratorAccountDialog?.email}</p>
        <p>{state?.decoratorAccountDialog?.password}</p>
      </StyledUserCred>
    </Dialog>
  );
};

DecoratorAccountDialog.propTypes = propTypes;

DecoratorAccountDialog.defaultProps = defaultValue;

export default DecoratorAccountDialog;
