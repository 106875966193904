import { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { usePost } from "hooks/usePost";
import DialogTitle from "@mui/material/DialogTitle";
import OrderDetailDialogAction from "components/IMSOrderCard/OrderDetailDialogActions";
import { propTypes, defaultProps } from "components/IMSOrderCard/types";
import OrderDetailLineItems from "components/IMSOrderCard/OrderDetailLineItems";
import OrderNotes from "components/IMSOrderCard/OrderNotes";
import {
  IMS_RETAIL_ORDER_EVENTS,
  TRACKING_NUMBER_US,
} from "constants/services";
import {
  Row,
  RowHeader,
  StyledStepLabel,
  TrackingNumber,
  OrderDetailWrapper,
} from "styles/components/order-card";

const OrderDetailDialog = ({ open, setOpen, data }) => {
  const orderNumber = data?.cart?.header[0]?.sessionid[0];
  const { mutateAsync } = usePost();
  const [events, setEvents] = useState(null);
  const [trackingInfo, setTrackingInfo] = useState(null);

  useEffect(() => {
    (async () => {
      if (orderNumber && !events) {
        const { data: response } = await mutateAsync({
          url: IMS_RETAIL_ORDER_EVENTS,
          data: {
            id: orderNumber,
          },
        });

        const { data: t } = await mutateAsync({
          url: TRACKING_NUMBER_US,
          data: {
            orderId: `FICO-${orderNumber}`,
          },
        });

        setTrackingInfo(t);
        setEvents(response);
      }
    })();
  }, [orderNumber]);

  return (
    <Dialog open={open} onClose={setOpen} fullWidth maxWidth="lg">
      <DialogTitle
        style={{ borderBottom: "1px solid black", marginBottom: "14px" }}
      >
        {orderNumber}
      </DialogTitle>
      <DialogContent>
        <div>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <RowHeader style={{ fontSize: "14px" }}>
              <h3 style={{ margin: 0, paddingBottom: "10px" }}>Ship Address</h3>
              {data?.cart?.header[0]?.shippinginfo[0]?.name[0]}&nbsp;
              <br />
              {data?.cart?.header[0]?.shippinginfo[0]?.address[0]}
              {data?.cart?.header[0]?.shippinginfo[0]?.address2[0]}&nbsp;
              <br />
              {data?.cart?.header[0]?.shippinginfo[0]?.city[0]}&nbsp;
              {data?.cart?.header[0]?.shippinginfo[0]?.state[0]}&nbsp;
              {data?.cart?.header[0]?.shippinginfo[0]?.country[0]}&nbsp;
              {data?.cart?.header[0]?.shippinginfo[0]?.zip[0]}
            </RowHeader>

            <RowHeader>
              <Stepper activeStep={events?.length} alternativeLabel>
                {events?.map((e) => (
                  <Step key={e?.action}>
                    <StyledStepLabel>
                      {e?.action?.toUpperCase()}
                      <br />
                      {moment(e?.time).format("MMM DD,YYYY")}
                      <br />
                      {e?.action === "shipped" &&
                        trackingInfo?.shipments?.map((t) => (
                          <TrackingNumber key={t?.trackingNumber}>
                            {t?.trackingNumber}
                          </TrackingNumber>
                        ))}
                    </StyledStepLabel>
                  </Step>
                ))}
              </Stepper>
            </RowHeader>
          </Row>

          <OrderDetailWrapper>
            <OrderDetailLineItems data={data} />
            <OrderNotes />
          </OrderDetailWrapper>
        </div>
      </DialogContent>

      <OrderDetailDialogAction data={data} setOpen={setOpen} />
    </Dialog>
  );
};

OrderDetailDialog.propTypes = propTypes;

OrderDetailDialog.defaultProps = defaultProps;

export default OrderDetailDialog;
