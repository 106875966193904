import styled from "styled-components";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const Filter = styled.div`
  display: flex;
  gap: 20px;
`;

export const OrderDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`;
export const NoDataFound = styled.h3`
  text-align: center;
  color: #a1a1a1;
`;
