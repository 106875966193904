import { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import OrderEstimationTransition from "pages/order/OrderEstimationTransition";
import OrderEstimationDialogHeader from "pages/order/OrderEstimationDialogHeader";
import OrderEstimationDialogSubHeader from "pages/order/OrderEstimationDialogSubHeader";
import OrderEstimationLineItems from "pages/order/OrderEstimationLineItems";
import OrderEstimationOtherCharges from "pages/order/OrderEstimationOtherCharges";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import {
  CONFLUENCE_PURCHASE_ORDER,
  QUICK_BOOKS_PDF_GENERATION,
  QUICK_BOOKS_SALES_CODE,
  QUICK_BOOKS_ESTIMATE,
  APP_VERSION,
} from "constants/services";
import { getSubtotal, getTax } from "pages/order/util";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { OrderEstimationDialogBody } from "styles/pages/order";

const OrderEstimationDialog = ({ state, setState, fetchOrder }) => {
  const user = useSelector((s) => s?.user);
  const appVersion = useSelector((s) => s?.appVersion?.version);
  const { mutateAsync } = usePost();
  const dispatch = useDispatch();

  const { refetch: qbSalesCodeRefetch } = useFetch(
    `${QUICK_BOOKS_SALES_CODE}?qbCountry=${state?.printProvider?.id}`,
    `${QUICK_BOOKS_SALES_CODE}?qbCountry=${state?.printProvider?.id}`,
    {
      enabled: false,
    }
  );

  const { refetch: appVersionRefetch } = useFetch(APP_VERSION, APP_VERSION, {
    enabled: false,
  });

  const compareVersion = async () => {
    const { data } = await appVersionRefetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    }
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  const handleClose = () =>
    setState({
      ...state,
      orderEstimationDialog: false,
      editedLineItems: [],
      editedOtherCharges: [],
    });

  const fetchPurchaseOrderInfo = async () => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_PURCHASE_ORDER,
      data: {
        orderNumber: state?.orderNumber,
        vendorName: state?.vendorName,
      },
    });

    const { data: qbSalesCodes } = await qbSalesCodeRefetch();

    setState({
      ...state,
      qbSalesCodes,
      estimateData: {
        ...data,
        invoiceDate: data?.invoiceDate || moment().unix(),
        invoiceTerms: data?.invoiceTerms || "Net 45",
        invoiceDueDate: data?.invoiceDueDate || moment().add(45, "days").unix(),
      },
    });
  };

  const updateOrderEstimate = async () => {
    await mutateAsync({
      url: CONFLUENCE_PURCHASE_ORDER,
      isPatch: true,
      data: {
        orderNumber: state?.orderNumber,
        vendorName: state?.vendorName,
        estimateData: {
          ...state?.estimateData,
          lineItems: state?.editedLineItems,
          otherCharges: state?.editedOtherCharges,
        },
      },
    });

    await fetchOrder();
    handleClose();
  };

  const createEstimate = async () => {
    const taxInfo = getTax(state);
    const subTotal = getSubtotal(state);
    const total = subTotal + taxInfo.value;
    const estimateData = state?.estimateData;
    const qbCutomers = estimateData?.qbCutomers;
    const customer = qbCutomers?.Customer;
    const email = customer?.PrimaryEmailAddr?.Address;
    const shipAddress = estimateData?.shipAddress;

    const payload = {
      TotalAmt: Number(total),
      BillEmail: {
        Address: email,
      },
      CustomerMemo: {
        value: "Thank you for your business and have a great day!",
      },
      CustomField: [
        {
          DefinitionId: "1",
          Name: "Salesperson",
          Type: "StringType",
          StringValue:
            state?.printProvider?.id === "US"
              ? state?.orderNumber
              : user?.email,
        },
      ],
      ShipAddr: {
        City: shipAddress?.city,
        Line1: shipAddress?.shipName,
        Line2: shipAddress?.address1,
        Line3: shipAddress?.address2,
        PostalCode: shipAddress?.zip,
        CountrySubDivisionCode: shipAddress?.country,
      },
      PrintStatus: "NeedToPrint",
      EmailStatus: "NeedToSend",
      BillAddr: customer?.BillAddr,
      Line: state?.editedLineItems?.map((l, i) => {
        const salesItemLineDetails = {
          Qty: l?.qty,
          UnitPrice: l?.rate,
          ItemRef: {
            name: l?.description?.DisplayName,
            value: l?.qbItemInfo?.QueryResponse?.Item[0]?.Id,
          },
        };

        if (state?.printProvider?.id !== "US") {
          salesItemLineDetails.TaxCodeRef = {
            name: estimateData?.qbSalesCode?.DisplayName,
            value: estimateData?.qbSalesCode?.Id,
          };
        }

        return {
          Description: l?.description?.DisplayName,
          DetailType: "SalesItemLineDetail",
          SalesItemLineDetail: salesItemLineDetails,
          LineNum: i + 1,
          Amount: Number(
            (Number(l?.rate || 0) * Number(l?.qty || 0)).toFixed(2)
          ),
          Id: i + 1,
        };
      }),
      CustomerRef: {
        name: customer?.CompanyName,
        value: customer?.Id,
      },
    };

    const { data: quickBooksEstimateResponse } = await mutateAsync({
      url: `${QUICK_BOOKS_ESTIMATE}?qbCountry=${state?.printProvider?.id}`,
      data: payload,
    });

    const docNumber =
      quickBooksEstimateResponse?.Estimate?.DocNumber ||
      quickBooksEstimateResponse?.Estimate?.Id;

    if (docNumber) {
      await mutateAsync({
        url: CONFLUENCE_PURCHASE_ORDER,
        isPatch: true,
        data: {
          orderNumber: state?.orderNumber,
          vendorName: state?.vendorName,
          estimateData: {
            ...state?.estimateData,
            lineItems: state?.editedLineItems,
            otherCharges: state?.editedOtherCharges,
            invoice: docNumber,
            qbEstimateData: quickBooksEstimateResponse,
          },
        },
      });

      window.location.reload();
    }
  };

  const generatePdf = async () => {
    await mutateAsync({
      url: `${QUICK_BOOKS_PDF_GENERATION}?qbCountry=${state?.printProvider?.id}`,
      data: {
        estimateId: "14279",
      },
    });
  };

  useEffect(() => {
    fetchPurchaseOrderInfo();
  }, []);

  return (
    <Dialog
      fullScreen
      open={state?.orderEstimationDialog}
      onClose={handleClose}
      TransitionComponent={OrderEstimationTransition}
    >
      <OrderEstimationDialogHeader
        state={state}
        setState={setState}
        updateOrderEstimate={updateOrderEstimate}
        generatePdf={generatePdf}
        createEstimate={createEstimate}
      />
      <OrderEstimationDialogBody maxWidth="lg">
        <OrderEstimationDialogSubHeader state={state} setState={setState} />
        <OrderEstimationLineItems state={state} setState={setState} />
        <OrderEstimationOtherCharges state={state} setState={setState} />
      </OrderEstimationDialogBody>
    </Dialog>
  );
};

OrderEstimationDialog.propTypes = PROP_TYPES;

OrderEstimationDialog.defaultProps = DEFAULT_STATE;

export default OrderEstimationDialog;
