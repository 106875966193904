import AddVendorDialog from "pages/vendors/AddVendorDialog";
import { propTypes, defaultProps } from "pages/vendors/types";
import {
  StyledHeader,
  StyledTitle,
  Icons,
  StyledAddCircleIcon,
  VendorUsersListButton,
} from "styles/pages/vendors";

const Header = ({ state, setState, onVendorsUsersList }) => (
  <StyledHeader>
    <StyledTitle variant="h5">Vendors</StyledTitle>

    <Icons>
      <VendorUsersListButton onClick={onVendorsUsersList}>
        Vendors Users List
      </VendorUsersListButton>
      <StyledAddCircleIcon
        onClick={() =>
          setState({ ...state, addvendorPayload: { scanningComplete: false } })
        }
      />
    </Icons>

    {!!state?.addvendorPayload && (
      <AddVendorDialog state={state} setState={setState} />
    )}
  </StyledHeader>
);

Header.propTypes = propTypes;

Header.defaultProps = defaultProps;

export default Header;
