import { propTypes, defaultProps } from "components/IMSOrderCard/types";
import { Row, RowHeader, SubRow, Title } from "styles/components/order-card";

const OrderDetailLineItems = ({ data }) => (
  <div>
    <Title>Line Items</Title>
    {data?.cart?.item?.map((d, index) => {
      const key = `item${index}`;
      const productName = d?.productname[0];
      return (
        <Row key={key}>
          <SubRow>
            <img src={d?.quickpicthumblink[0]} alt="thumb" />
          </SubRow>
          <RowHeader>
            <div>Product Name : {productName}</div>
            <div>Size : {d?.customfield1[0]?.value[0]}</div>
            <div>Color : {d?.color[0]}</div>
            <div>Qty : {d?.qty1[0]}</div>

            <div>
              {!!d?.customfield2[0]?.value[0] && (
                <span>
                  {d?.customfield2[0]?.name[0]} : &nbsp;
                  {d?.customfield2[0]?.value[0]}
                </span>
              )}
            </div>
            <div>
              {!!d?.customfield3[0]?.value[0] && (
                <span>
                  {d?.customfield3[0]?.name[0]} : &nbsp;
                  {d?.customfield3[0]?.value[0]}
                </span>
              )}
            </div>
            <div>
              {!!d?.customfield4[0]?.value[0] && (
                <span>
                  {d?.customfield4[0]?.name[0]} : &nbsp;
                  {d?.customfield4[0]?.value[0]}
                </span>
              )}
            </div>
          </RowHeader>
        </Row>
      );
    })}
  </div>
);

OrderDetailLineItems.propTypes = propTypes;

OrderDetailLineItems.defaultProps = defaultProps;

export default OrderDetailLineItems;
