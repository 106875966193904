import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import PageTitle from "components/PageTitle";
import SearchTextFieldInput from "components/SearchTextFieldInput";
import TagsAutocomplete from "components/TagsAutocomplete";
import Pagination from "components/Pagination";
import OrderCard from "components/IMSOrderCard";
import Tabs from "components/Tabs";
import { usePost } from "hooks/usePost";
import { setTab } from "features/ims-orders/imsOrdersSlice";
import { IMS_RETAIL_ORDERS, IMS_RETAIL_LOGOS } from "constants/services";
import {
  StyledContainer,
  Filter,
  OrderDetails,
  NoDataFound,
} from "styles/pages/ims-orders";

const tabs = [
  { title: "Pending", value: "Pending" },
  { title: "In-Production", value: "Processed" },
  { title: "Shipped", value: "Shipped" },
  { title: "Declined", value: "Declined" },
];
const take = 8;

const IMSOrders = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const { mutateAsync } = usePost();
  const { selectedTabIndex, page, search } = useSelector((s) => s?.imsOrders);

  const fetchOrders = async () => {
    const { data: orders } = await mutateAsync({
      url: IMS_RETAIL_ORDERS,
      data: {
        take,
        skip: (page - 1) * take,
        logoName: search ? null : state?.logoName?.value || null,
        status: search ? null : tabs[selectedTabIndex]?.value,
        search: search || null,
      },
    });

    setState({
      ...state,
      orders,
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [page, selectedTabIndex, state?.logoName]);

  return (
    <StyledContainer fixed>
      <PageTitle title="IMS Orders (SAGE E-Commerce)" />

      <Filter>
        <SearchTextFieldInput
          label="Search Order"
          value={search}
          handleChange={(v) =>
            dispatch(setTab({ selectedTabIndex, page, search: v }))
          }
          handleSubmit={() => {
            if (page === 1) {
              fetchOrders();
            } else {
              dispatch(setTab({ selectedTabIndex, page: 1 }));
            }
          }}
        />

        <TagsAutocomplete
          multiple={false}
          optionApiKey="logoName"
          apiEndPoint={IMS_RETAIL_LOGOS}
          value={state?.logoName || { title: "", value: "" }}
          setValue={(logoName) => {
            if (search) {
              setState({ ...state, logoName });
              dispatch(setTab({ selectedTabIndex, page, search: "" }));
            } else {
              setState({ ...state, logoName });
            }
          }}
          label="Logos"
        />
      </Filter>

      <Tabs
        value={selectedTabIndex}
        disabled={!!search}
        tabs={tabs.map((t) => t?.title)}
        onTabChange={(v) => {
          setState({
            ...state,
            quickFilter: undefined,
            quickFilterInputValue: undefined,
          });
          dispatch(setTab({ selectedTabIndex: v, page: 1 }));
        }}
      >
        <OrderDetails>
          {state?.orders?.data?.length > 0 && (
            <Pagination
              count={Math.ceil(state.orders.totalCount / take)}
              page={page}
              handleChange={(e, v) =>
                dispatch(setTab({ selectedTabIndex, page: v }))
              }
            />
          )}
        </OrderDetails>

        <Grid container spacing={3} sx={{ marginTop: "1px" }}>
          {state?.orders?.data?.map((o) => (
            <Grid item xs={3} key={`${o?.orderNumber}_${o?.vendorName}`}>
              <OrderCard
                data={{ ...o, status: tabs[selectedTabIndex]?.value }}
                refresh={fetchOrders}
                // handleClick={() =>
                //   navigate(`${ORDER}/${o?.orderNumber}/${o?.vendorName}`)
                // }
              />
            </Grid>
          ))}
        </Grid>

        {!state?.orders?.data?.length && (
          <NoDataFound>No data found !</NoDataFound>
        )}
      </Tabs>
    </StyledContainer>
  );
};

export default IMSOrders;
