import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { store } from "root-store";
import { BASE_URL } from "constants/services";
import { logout } from "features/user/userSlice";
import { CUSTOMER_TYPE } from "constants/routes";

const HttpService = axios.create({
  baseURL: BASE_URL,
});

HttpService.interceptors.request.use(
  (config) => {
    const token = store.getState()?.user?.authToken;
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  HttpService.interceptors.response.use(
    (response) => {
      if (response?.data?.tokenExpired) {
        dispatch(logout());
        navigate(CUSTOMER_TYPE, { replace: true });
        return false;
      }
      return response;
    },
    (error) => Promise.reject(error)
  );

  return children;
};

export default HttpService;

export { AxiosInterceptor };
