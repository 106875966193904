import Table from "@mui/material/Table";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextFieldInput from "components/TextFieldInput";
import { propTypes, defaultValue } from "pages/bot-network/types";
import {
  SalesReportTableInput,
  SalesReportTableTextarea,
} from "styles/pages/bot-network";

const ccyFormat = (num) => `${num.toFixed(2)}`;

const createRow = (
  orderNumber,
  description,
  qty,
  chargeAmount,
  color,
  size,
  decorationInput
) => ({
  orderNumber,
  description,
  qty,
  chargeAmount,
  color,
  size,
  decorationInput,
});

const subtotal = (items) =>
  items.map(({ chargeAmount }) => chargeAmount).reduce((sum, i) => sum + i, 0);

const SalesReportOrders = ({ state, setState }) => {
  const orders = state?.salesReportDialog?.orders;
  const taxRate = state?.salesReportDialog?.taxRate || 0;
  const deductionDescription = state?.salesReportDialog?.deductionDescription;
  const deductionAmount = state?.salesReportDialog?.deductionAmount || 0;

  const rows = orders?.map((o) =>
    createRow(
      o?.orderNumber,
      o?.description,
      o?.qty,
      o?.chargeAmount,
      o?.color,
      o?.size,
      o?.decorationInput
    )
  );

  const invoiceSubtotal = subtotal(rows) - deductionAmount;
  const invoiceTaxes = (taxRate * invoiceSubtotal) / 100;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell>Desciption</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Decoration Input</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            const key = `${row.desc}_${row?.orderNumber}_${i}`;
            return (
              <TableRow key={key}>
                <TableCell>{row.orderNumber}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.color}</TableCell>
                <TableCell>{row.size}</TableCell>
                <TableCell>{row.decorationInput}</TableCell>
                <TableCell align="right">{row.qty}</TableCell>
                <TableCell align="right">
                  <SalesReportTableInput>
                    <TextFieldInput
                      value={row?.chargeAmount}
                      type="number"
                      handleChange={(e) =>
                        setState({
                          ...state,
                          salesReportDialog: {
                            ...state?.salesReportDialog,
                            orders: orders?.map((o, j) => {
                              if (i !== j) return { ...o };
                              return {
                                ...o,
                                chargeAmount: Number(e?.target?.value),
                              };
                            }),
                          },
                        })
                      }
                    />
                  </SalesReportTableInput>
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell colSpan={2}>
              <SalesReportTableTextarea>
                <TextareaAutosize
                  placeholder="Deduction Description"
                  minRows={3}
                  value={deductionDescription}
                  onChange={(e) =>
                    setState({
                      ...state,
                      salesReportDialog: {
                        ...state?.salesReportDialog,
                        deductionDescription: e?.target?.value,
                      },
                    })
                  }
                />
              </SalesReportTableTextarea>
            </TableCell>
            <TableCell colSpan={4}>Deduction</TableCell>
            <TableCell>
              <SalesReportTableInput>
                <TextFieldInput
                  value={deductionAmount}
                  type="number"
                  placeholder="Deduction Amount"
                  handleChange={(e) =>
                    setState({
                      ...state,
                      salesReportDialog: {
                        ...state?.salesReportDialog,
                        deductionAmount:
                          Number(e?.target?.value) >= 0
                            ? Number(e?.target?.value)
                            : 0,
                      },
                    })
                  }
                />
              </SalesReportTableInput>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={2} rowSpan={3} />
            <TableCell colSpan={4}>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tax</TableCell>

            <TableCell align="right" colSpan={3}>
              <TextFieldInput
                label="Tax Rate"
                type="Number"
                value={taxRate}
                handleChange={(e) =>
                  setState({
                    ...state,
                    salesReportDialog: {
                      ...state?.salesReportDialog,
                      taxRate: Number(e?.target?.value),
                    },
                  })
                }
              />
            </TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>Total</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SalesReportOrders.propTypes = propTypes;

SalesReportOrders.defaultProps = defaultValue;

export default SalesReportOrders;
