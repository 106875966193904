import CryptoJS from "crypto-js";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import UserInformationDialog from "pages/bot-network/UserInformationDialog";
import DecoratorAccountDialog from "pages/bot-network/DecoratorAccountDialog";
import SalesReportDialog from "pages/bot-network/SalesReportDialog";
import OrdersDialog from "pages/bot-network/OrdersDialog";
import { propTypes, defaultValue } from "pages/bot-network/types";
import { CRYPTO_KEY } from "constants/services";
import {
  Wrapper,
  StyledBox,
  StyledPaper,
  StyledAvatar,
  StyledEmail,
  StyledCompanyName,
  StyledEditIcon,
  StyledAccountCircleIcon,
  StyledInfo,
  StyledStack,
  StyledPictureAsPdfIcon,
} from "styles/pages/bot-network";

const Users = ({ state, setState }) => (
  <Wrapper>
    <StyledBox>
      {state?.users?.map((user) => {
        const email = user?.email;
        return (
          <StyledPaper
            key={email}
            status={user?.status}
            style={{ position: "relative" }}
            onClick={() => {
              setState({ ...state, botNetworkOrderDialog: user });
            }}
          >
            <Tooltip title="Sales Report" arrow>
              <StyledPictureAsPdfIcon
                onClick={(e) => {
                  e?.stopPropagation();
                  setState({ ...state, salesReportDialog: user });
                }}
              />
            </Tooltip>

            <StyledEditIcon
              onClick={(e) => {
                e?.stopPropagation();
                setState({
                  ...state,
                  selectedUser: {
                    ...user,
                    password: user?.password
                      ? CryptoJS.AES.decrypt(
                          user?.password,
                          CRYPTO_KEY
                        ).toString(CryptoJS.enc.Utf8)
                      : undefined,
                  },
                });
              }}
            />

            <StyledInfo>
              <StyledAvatar src={user?.profileImg} />
              <div>
                <Tooltip title={email} arrow>
                  <StyledEmail>{email}</StyledEmail>
                </Tooltip>
                <StyledCompanyName>{user?.companyName}</StyledCompanyName>
              </div>
            </StyledInfo>

            <StyledStack direction="row">
              {user?.ordersCount?.map((o) => (
                <Chip
                  key={o?.status}
                  avatar={
                    <Avatar
                      src={`https://api.dicebear.com/5.x/initials/svg?seed=${o?.count}`}
                    />
                  }
                  label={o?.status}
                />
              ))}
            </StyledStack>
            <Tooltip title="In-Personate Profile" arrow>
              <StyledAccountCircleIcon
                onClick={(e) => {
                  e?.stopPropagation();
                  setState({
                    ...state,
                    decoratorAccountDialog: {
                      ...user,
                      password: user?.password
                        ? CryptoJS.AES.decrypt(
                            user?.password,
                            CRYPTO_KEY
                          ).toString(CryptoJS.enc.Utf8)
                        : undefined,
                    },
                  });
                }}
              />
            </Tooltip>
          </StyledPaper>
        );
      })}

      {state?.decoratorAccountDialog && (
        <DecoratorAccountDialog state={state} setState={setState} />
      )}

      {state?.botNetworkOrderDialog && (
        <OrdersDialog state={state} setState={setState} />
      )}

      {state?.salesReportDialog && (
        <SalesReportDialog state={state} setState={setState} />
      )}
    </StyledBox>

    {!!state?.selectedUser && (
      <UserInformationDialog state={state} setState={setState} />
    )}
  </Wrapper>
);

Users.propTypes = propTypes;

Users.defaultValue = defaultValue;

export default Users;
