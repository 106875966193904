import { useState } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import Button from "components/LoadingButton";
import Snackbar from "components/SnackBar";
import OrderComments from "components/OrderCard/OrderComments";
import { DECORATOR_COMMENTS } from "constants/services";
import { StyledAddCommentRow } from "styles/components/order-card";
import {
  StyledComments,
  StyledCommentsTitle,
  StyledAllComments,
} from "styles/pages/order";

const OrderCommentsDialog = ({ order, refresh }) => {
  const [state, setState] = useState({});
  const { mutateAsync } = usePost();

  const { refetch: refetchComments } = useFetch(
    DECORATOR_COMMENTS,
    `${DECORATOR_COMMENTS}/${order?.vendorName}/${order?.orderNumber}`,
    {
      enabled: false,
    }
  );

  const fetchComments = async () => {
    const { data } = await refetchComments();
    if (data) {
      setState({ ...state, comments: data });
    }
  };

  const addComent = async () => {
    const { data } = await mutateAsync({
      url: `${DECORATOR_COMMENTS}/${order?.vendorName}/${order?.orderNumber}`,
      data: { comment: state?.comment },
    });

    if (data) {
      setState({
        ...state,
        comments: data,
        snackbarMessageSeverity: "success",
        snackbarMessage: "Comment added successfully !",
      });
      refresh();
    }
  };

  useState(() => {
    if (!state?.comments) fetchComments();
  }, [state]);

  return (
    <StyledComments>
      <StyledCommentsTitle>Support / Comments</StyledCommentsTitle>

      <StyledAllComments>
        {state?.comments && <OrderComments comments={state?.comments} />}

        <StyledAddCommentRow>
          <TextareaAutosize
            placeholder="Comment"
            minRows={3}
            value={state?.comment}
            onKeyDown={(e) => {
              if (e?.key === "Enter" && !!state?.comment) {
                addComent();
              }
            }}
            onChange={(e) => setState({ ...state, comment: e?.target?.value })}
          />
          <Button
            label="Submit"
            variant="contained"
            disabled={!state?.comment?.trim()}
            onClick={addComent}
          />
        </StyledAddCommentRow>
      </StyledAllComments>

      {!!state?.snackbarMessage && (
        <Snackbar
          message={state?.snackbarMessage}
          onClose={() =>
            setState({
              ...state,
              snackbarMessage: null,
              snackbarMessageSeverity: null,
            })
          }
          severity={state?.snackbarMessageSeverity}
        />
      )}
    </StyledComments>
  );
};

OrderCommentsDialog.propTypes = {
  refresh: PropTypes.func,
  order: PropTypes.shape({
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
  }),
};

OrderCommentsDialog.defaultProps = {
  order: { orderNumber: "", vendorName: "" },
  refresh: () => {},
};

export default OrderCommentsDialog;
