import { useNavigate } from "react-router-dom";
import LoadingButton from "components/LoadingButton";
import { LOGIN } from "constants/routes";
import Logo from "assets/images/logo.png";
import {
  Container,
  Wrapper,
  StyledLogo,
  Title,
  Footer,
} from "styles/pages/customer-type";

const CustomerTypePage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <StyledLogo alt="logo" src={Logo} />
        <Title>Select Customer Type</Title>
        <Footer>
          <LoadingButton
            size="large"
            label="Customer"
            variant="contained"
            onClick={() => window.open("https://bottrutrac.com/login")}
          />
          <LoadingButton
            size="large"
            label="Admin"
            variant="contained"
            onClick={() => navigate(LOGIN)}
          />
        </Footer>
      </Wrapper>
    </Container>
  );
};

export default CustomerTypePage;
