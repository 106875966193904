import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DeleteConfirmDialog = ({ open, onDelete, onCancel }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Alert</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure, you want to delete this user?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>No</Button>
      <Button onClick={onDelete} autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

DeleteConfirmDialog.defaultProps = {
  open: false,
  onDelete: () => {},
  onCancel: () => {},
};

export default DeleteConfirmDialog;
