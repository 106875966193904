import Paper from "@mui/material/Paper";
import TextField from "components/TextFieldInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  StyledTableRow,
  StyledTableRow1,
  StyledTableRow2,
  WorkOrderHeading,
  StyledDisabledByDefaultIcon,
} from "styles/pages/order";

const DecoratorOtherCharges = ({ decoratorData, setDecoratorData }) => {
  const handleOtherChargesChange = (key, value, index) => {
    setDecoratorData({
      ...decoratorData,
      otherCharges: decoratorData?.otherCharges?.map((row, j) => {
        if (index !== j) return row;
        return {
          ...row,
          [key]: value,
        };
      }),
    });
  };

  const getTotalAmount = () => {
    let totalAmount = 0;

    for (let i = 0; i < decoratorData?.lineItems.length; i += 1) {
      totalAmount += decoratorData?.lineItems[i]?.chargeAmount || 0;
    }
    for (let i = 0; i < decoratorData?.otherCharges.length; i += 1) {
      totalAmount += decoratorData?.otherCharges[i]?.chargeAmount || 0;
    }

    return totalAmount;
  };

  return (
    <>
      <Paper elevation={3} sx={{ marginTop: "20px" }}>
        <WorkOrderHeading>
          <div>Other Charges</div>
          <AddCircleIcon
            sx={{ cursor: "pointer" }}
            onClick={() =>
              setDecoratorData({
                ...decoratorData,
                otherCharges: [{}, ...decoratorData.otherCharges],
              })
            }
          />
        </WorkOrderHeading>
        {decoratorData?.otherCharges.map((row, i) => {
          const key = i;
          return (
            <StyledTableRow key={`editedLineItems_${key}`} even={i % 2 === 0}>
              <StyledDisabledByDefaultIcon
                onClick={() =>
                  setDecoratorData({
                    ...decoratorData,
                    otherCharges: decoratorData?.otherCharges?.filter(
                      (r, j) => i !== j
                    ),
                  })
                }
              />
              <StyledTableRow1>
                <TextField
                  label="Description"
                  error={!row?.description?.trim()}
                  value={row?.description}
                  handleChange={(e) =>
                    handleOtherChargesChange("description", e?.target?.value, i)
                  }
                />
              </StyledTableRow1>
              <StyledTableRow2>
                <TextField
                  label="Quantity"
                  error={!row?.qty}
                  value={row?.qty}
                  type="number"
                  handleChange={(e) =>
                    setDecoratorData({
                      ...decoratorData,
                      otherCharges: decoratorData?.otherCharges?.map((r, j) => {
                        if (i !== j) return r;
                        return {
                          ...r,
                          qty: Number(e?.target?.value),
                          chargeAmount: Number(e?.target?.value) * row.unitCost,
                        };
                      }),
                    })
                  }
                />

                <TextField
                  label="Unit Cost"
                  error={!row?.unitCost}
                  value={row?.unitCost}
                  type="number"
                  handleChange={(e) =>
                    setDecoratorData({
                      ...decoratorData,
                      otherCharges: decoratorData?.otherCharges?.map((r, j) => {
                        if (i !== j) return r;
                        return {
                          ...r,
                          unitCost: Number(e?.target?.value),
                          chargeAmount: Number(e?.target?.value) * row.qty,
                        };
                      }),
                    })
                  }
                />
                <TextField
                  label="Charge Amount"
                  error={!row?.chargeAmount}
                  value={row?.chargeAmount || 0}
                  disabled
                />
              </StyledTableRow2>
            </StyledTableRow>
          );
        })}
      </Paper>
      <p style={{ textAlign: "right" }}>
        <b>
          Total Amount:&nbsp;
          <span style={{ fontSize: "30px" }}>{getTotalAmount()}</span>
        </b>
      </p>
      <p>
        <b>
          Please email us at customerservice@bigoventees.com if you have any
          questions or concerns.
        </b>
      </p>
    </>
  );
};

DecoratorOtherCharges.propTypes = PROP_TYPES;

DecoratorOtherCharges.defaultProps = DEFAULT_STATE;

export default DecoratorOtherCharges;
