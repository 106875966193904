import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Snackbar from "components/SnackBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldInput from "components/TextFieldInput";
import { usePost } from "hooks/usePost";
import { CRYPTO_KEY, VENDOR_USERS } from "constants/services";
import validateEmail from "utils/email-regex";
import { UserForm } from "styles/pages/vendors-users";

const UserDialog = ({ open, hide, data, refresh }) => {
  const [error, setError] = useState();
  const { mutateAsync } = usePost();
  const [state, setState] = useState();
  const create = data?.create;
  const handleClose = () => hide();

  useEffect(() => {
    if (data && !state)
      setState({
        ...data,
        password: CryptoJS.AES.decrypt(
          data?.password || "",
          CRYPTO_KEY
        ).toString(CryptoJS.enc.Utf8),
      });
  }, [data]);

  const save = async () => {
    const payload = {
      vendorName: state?.vendorName,
      clientEmail: state?.clientEmail,
      password: CryptoJS.AES.encrypt(state?.password, CRYPTO_KEY).toString(),
    };
    const { data: response } = await mutateAsync({
      url: VENDOR_USERS,
      data: payload,
      isPut: !create,
    });

    if (response?.success) {
      refresh();
    } else {
      setError(response?.message);
    }
  };

  const disabled =
    !state?.clientEmail?.trim() ||
    !validateEmail(state?.clientEmail) ||
    !state?.password;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{create ? "Add" : "Edit"} User</DialogTitle>
      <DialogContent>
        <UserForm>
          <TextFieldInput
            disabled={!create}
            value={state?.clientEmail}
            label="Email"
            handleChange={(e) =>
              setState({ ...state, clientEmail: e?.target?.value })
            }
          />

          <TextFieldInput
            value={state?.password}
            label="Password"
            handleChange={(e) =>
              setState({ ...state, password: e?.target?.value })
            }
          />
        </UserForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={save} disabled={disabled}>
          {create ? "Add" : "Update"}
        </Button>
      </DialogActions>

      {!!error && (
        <Snackbar
          message={error}
          onClose={() => setError(null)}
          severity="error"
        />
      )}
    </Dialog>
  );
};

UserDialog.propTypes = {
  open: PropTypes.bool,
  hide: PropTypes.func,
  refresh: PropTypes.func,
  data: PropTypes.shape({
    create: PropTypes.bool,
    password: PropTypes.string,
    vendorName: PropTypes.string,
    clientEmail: PropTypes.string,
  }),
};

UserDialog.defaultProps = {
  open: false,
  hide: () => {},
  refresh: () => {},
  data: {
    create: false,
    password: "",
    vendorName: "",
    clientEmail: "",
  },
};

export default UserDialog;
