import { forwardRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import PageTitle from "components/PageTitle";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_ORDERS } from "constants/services";
import Tabs from "components/Tabs";
import Pagination from "components/Pagination";
import OrderCard from "components/OrderCard";
import { propTypes, defaultValue } from "pages/bot-network/types";
import { BOT_NETWORK_TABS_TITLES } from "pages/orders/data";
import { ORDER } from "constants/routes";
import {
  //   StyledContainer,
  NoDataFound,
  //   Filter,
  //   OrderDetails,
  //   QuickFilters,
} from "styles/pages/orders";

import { OrdersWrapper, StyledPagination } from "styles/pages/bot-network";

const take = 8;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrdersDialog = ({ state, setState }) => {
  const navigate = useNavigate();
  const { mutateAsync } = usePost();
  const [orders, setOrders] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(1);
  const selectedUser = state?.botNetworkOrderDialog;
  const companyName = selectedUser?.companyName;
  const open = !!selectedUser;
  const handleClose = () =>
    setState({ ...state, botNetworkOrderDialog: undefined });

  const fetchBotNetworkOrders = async () => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_ORDERS,
      data: {
        companyName,
        take,
        skip: (page - 1) * take,
        status: BOT_NETWORK_TABS_TITLES[tabIndex]?.value,
        search: null,
        decoration: null,
        artProofStatus: null,
      },
    });
    setOrders(data);
  };

  useEffect(() => {
    if (open) fetchBotNetworkOrders();
  }, [open, page, tabIndex]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            BOT Network ({companyName})
          </Typography>
        </Toolbar>
      </AppBar>

      <OrdersWrapper>
        <PageTitle title={`${companyName} Orders`} />

        <Tabs
          tabs={BOT_NETWORK_TABS_TITLES?.map((t) => t?.title)}
          onTabChange={(v) => {
            setPage(1);
            setTabIndex(v);
          }}
          value={tabIndex}
        >
          <StyledPagination>
            {orders?.data?.length > 0 && (
              <Pagination
                count={Math.ceil(orders.totalCount / take)}
                page={page}
                handleChange={(e, v) => setPage(v)}
              />
            )}
          </StyledPagination>

          <Grid container spacing={3} sx={{ marginTop: "1px" }}>
            {orders?.data?.map((o) => (
              <Grid item xs={3} key={`${o?.orderNumber}_${o?.vendorName}`}>
                <OrderCard
                  data={o}
                  isBotNetworkOrder
                  //   refresh={fetchOrders}
                  handleClick={() =>
                    navigate(`${ORDER}/${o?.orderNumber}/${o?.vendorName}`)
                  }
                />
              </Grid>
            ))}
          </Grid>

          {!orders?.data?.length && <NoDataFound>No data found !</NoDataFound>}
        </Tabs>
      </OrdersWrapper>
    </Dialog>
  );
};

OrdersDialog.propTypes = propTypes;

OrdersDialog.defaultProps = defaultValue;

export default OrdersDialog;
