import Tooltip from "@mui/material/Tooltip";
import DecorationInformationDialog from "pages/decorations/DecorationInformationDialog";
import { propTypes, defaultValue } from "pages/decorations/types";
import {
  Wrapper,
  StyledBox,
  StyledPaper,
  StyledAvatar,
  StyledTitle,
} from "styles/pages/decorations";

const Decorations = ({ state, setState }) => (
  <Wrapper>
    <StyledBox>
      {state?.decorations?.map((decoration) => {
        const { decoFullName, iconUrl } = decoration;
        return (
          <StyledPaper
            key={decoFullName}
            onClick={() =>
              setState({
                ...state,
                selectedDecoration: {
                  ...decoration,
                  edit: true,
                },
              })
            }
          >
            <StyledAvatar src={iconUrl} />

            <Tooltip title={decoFullName} arrow>
              <StyledTitle>{decoFullName}</StyledTitle>
            </Tooltip>
          </StyledPaper>
        );
      })}
    </StyledBox>

    {!!state?.selectedDecoration && (
      <DecorationInformationDialog state={state} setState={setState} />
    )}
  </Wrapper>
);

Decorations.propTypes = propTypes;

Decorations.defaultValue = defaultValue;

export default Decorations;
