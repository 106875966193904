import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TextFieldInput from "components/TextFieldInput";
import PasswordInput from "components/PasswordInput";
import Snackbar from "components/SnackBar";
import LoadingButton from "components/LoadingButton";
import { usePost } from "hooks/usePost";
import { setUser } from "features/user/userSlice";
import { CRYPTO_KEY, LOGIN } from "constants/services";
import { ORDERS } from "constants/routes";
import Logo from "assets/images/logo.png";
import {
  Wrapper,
  StyledForm,
  Footer,
  StyledLogo,
  Title,
  SubTitle,
} from "styles/pages/login";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [value, setValue] = useState();
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  return (
    <Wrapper>
      <StyledLogo alt="logo" src={Logo} />
      <Title>Sign in</Title>
      <SubTitle>to continue to TruTrac</SubTitle>
      <StyledForm>
        <TextFieldInput
          value={value}
          label="User"
          handleChange={(e) => setValue(e?.target?.value)}
        />

        <PasswordInput label="PIN" value={password} setValue={setPassword} />

        <Footer>
          <LoadingButton
            size="large"
            disabled={!value || !password}
            onClick={async () => {
              const payload = {
                email: value,
                pin: CryptoJS.AES.encrypt(password, CRYPTO_KEY).toString(),
              };

              const { data } = await mutateAsync({
                url: LOGIN,
                data: payload,
              });

              if (data?.authToken) {
                dispatch(setUser(data));
                navigate(ORDERS, { replace: true });
              } else {
                setError(data?.message);
              }
            }}
            label="Login"
            variant="contained"
          />
        </Footer>
      </StyledForm>

      <Snackbar
        message={error}
        onClose={() => setError(null)}
        severity="error"
      />
    </Wrapper>
  );
};

export default Form;
