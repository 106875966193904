import { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import OrderMarkShippedDialog from "components/OrderCard/OrderMarkShippedDialog";
import AttentionMessageDialog from "components/OrderCard/AttentionMessageDialog";
import EditOrderDialog from "components/OrderCard/EditOrderDialog";
import PaymentDetailsDialog from "components/OrderCard/PaymentDetailsDialog";
import UploadShippingLabelDialog from "components/OrderCard/UploadShippingLabelDialog";
import MoveOrderDialog from "components/OrderCard/MoveOrderDialog";
import OrderCheckInDialog from "components/OrderCard/OrderCheckInDialog";
import OrderCommentsDialog from "components/OrderCard/OrderCommentsDialog";
import DamageReportDialog from "components/OrderCard/DamageReportDialog";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FactoryIcon from "@mui/icons-material/Factory";
import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { usePost } from "hooks/usePost";
import {
  ORDER_STATUS,
  completed,
  inProduction,
  shipped,
  ready,
  defined,
} from "pages/orders/data";
import { getArtProofStatusTitle } from "components/OrderCard/utils";
import { CONFLUENCE_ORDERS } from "constants/services";
import { DEVELOPER_ROLE, CSR_ROLE } from "constants/roles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Container,
  Body,
  Wrapper,
  Status,
  Header,
  PONumber,
  VendorName,
  UploadDate,
  InProductionDate,
  ShippedDate,
  ArtProofWrapper,
  VendorLogo,
  MarkAsShipped,
  StyledCommentIcon,
  DecorationChips,
  DecorationChip,
  Comment,
  Flag,
  StyledCampaignIcon,
  StyledCheckIn,
  CornerDiv,
  CardActions,
  StyledEditIcon,
} from "styles/components/order-card";

const OrderCard = ({ data, handleClick, refresh, isBotNetworkOrder }) => {
  const user = useSelector((s) => s?.user);
  const [open, setOpen] = useState(false);
  const [uploadLabelDialog, toggleUploadLabelDialog] = useState(false);
  const [paymentDetailsDialog, togglepaymentDetailsDialog] = useState(false);
  const [moveOrderDialog, setMoveOrderDialog] = useState(false);
  const [attentionMessageDialogVisibility, setAttention] = useState(false);
  const [editOrderDialogVisibility, setEditOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [commentDialog, toggleCommentDialog] = useState(false);
  const [damageReportDialog, toggleDamageReportDialog] = useState(false);
  const [orderCheckInDialog, toggleOrderCheckInDialog] = useState(false);
  const { mutateAsync } = usePost();
  const isOrderCompleted = data?.status === completed?.value;
  const isOrderInProduction = data?.status === inProduction?.value;
  const isOrderDefined = data?.status === defined?.value;
  const isOrderInPreProd = data?.status === ready?.value;
  const isOrderShipped = data?.status === shipped?.value;
  const orderCheckInData = data?.orderCheckInData;
  const isUserCsr = user?.role === CSR_ROLE.role;
  const isUserDeveloper = user?.role === DEVELOPER_ROLE.role;
  const isCompletedStockStatus = !!orderCheckInData?.find(
    (o) => o?.stockStatus === "Complete"
  );

  const updateOrder = async (payload) => {
    await mutateAsync({
      url: CONFLUENCE_ORDERS,
      isPatch: true,
      data: payload,
    });
    refresh();
  };

  const openCommentDialog = (e) => {
    e.stopPropagation();
    setSelectedOrder(data);
    toggleCommentDialog(!commentDialog);
  };

  const getCheckInButton = () => {
    const style = { color: "white" };

    if (!orderCheckInData) {
      return { ...style, background: "#ff0000b0" };
    }

    if (isCompletedStockStatus) {
      return { ...style, background: "#07b607b0" };
    }

    return { ...style, background: "#ffa500c7" };
  };

  return (
    <>
      <Container>
        <Body
          status={data?.status}
          artproof={!!data?.artProof}
          onClick={handleClick}
          rushOrder={data?.rushOrder}
        >
          {!isBotNetworkOrder && (
            <Tooltip title="Attention Message" arrow>
              <StyledCampaignIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOrder(data);
                  setAttention(true);
                }}
              />
            </Tooltip>
          )}

          <StyledEditIcon
            onClick={(e) => {
              e.stopPropagation();
              setSelectedOrder(data);
              setEditOrder(true);
            }}
          />

          <VendorLogo
            src={data?.vendorLogo}
            onClick={(e) => e.stopPropagation()}
          >
            {data?.submitToDecorator ? (
              <div>
                <Tooltip
                  title={data?.decoratorData?.selectedDecorator?.companyName}
                >
                  <CornerDiv>
                    <FactoryIcon />
                  </CornerDiv>
                </Tooltip>
              </div>
            ) : (
              <Flag src={data?.flagUrl} alt="print-provider" />
            )}
            <Comment>
              <Tooltip title="Internal Team Comments" arrow>
                <Badge
                  badgeContent={data?.orderComments?.length}
                  color="error"
                  onClick={openCommentDialog}
                >
                  <StyledCommentIcon onClick={openCommentDialog} />
                </Badge>
              </Tooltip>
            </Comment>
          </VendorLogo>
          <Wrapper>
            {/* {data?.submitToDecorator && (
              <Tooltip title="Decorator">
                <CompanyName>
                  <SettingsInputComponentIcon />
                  {data?.decoratorData?.selectedDecorator?.companyName}
                </CompanyName>
              </Tooltip>
            )} */}
            <Header>
              <div>
                <PONumber>{data?.orderNumber}</PONumber>

                <VendorName>{data?.vendorName}</VendorName>
              </div>
              {data?.orderEvents?.length > 0 && (
                <Tooltip title={data?.orderEvents[0]?.name} arrow>
                  <Avatar
                    src={data?.orderEvents[0]?.profileImg}
                    sx={{
                      bgcolor: "#a1a1a1",
                      width: 32,
                      height: 32,
                      fontSize: 12,
                      border: "1px solid #a1a1a1",
                    }}
                  />
                </Tooltip>
              )}
            </Header>
            <div>
              <Status status={data?.status}>{data?.status}</Status>
            </div>
            <UploadDate>
              Created Date :&nbsp;
              {moment.unix(data?.createdDate).format("MMM DD,YYYY")}
            </UploadDate>
            {(data?.status === inProduction.value ||
              data?.status === completed.value ||
              data?.status === shipped.value) &&
              data?.inProductionDate && (
                <InProductionDate>
                  Production Date :&nbsp;
                  {moment.unix(data?.inProductionDate).format("MMM DD,YYYY")}
                </InProductionDate>
              )}
            {data?.status === shipped.value && !!data?.trackingDetails?.length && (
              <ShippedDate>
                Shipped Date :&nbsp;
                {moment
                  .unix(_.last(data?.trackingDetails)?.shippedDate)
                  .format("MMM DD,YYYY")}
              </ShippedDate>
            )}

            <DecorationChips>
              {data?.decorations?.map((d) => {
                const inProductionDateKey = `${d?.toLowerCase()}InProductionDate`;
                const completedDateKey = `${d?.toLowerCase()}CompletedDate`;
                const inProductionDate = data[inProductionDateKey];
                const completedDate = data[completedDateKey];
                return (
                  <Tooltip
                    title={`${
                      inProductionDate || completedDate
                        ? moment
                            .unix(completedDate || inProductionDate)
                            .format("MMM DD, YYYY")
                        : `Not Set`
                    }${
                      d === "SCREENPRINT" && data?.totalPrintLocations
                        ? ` , Print Locations : ${data?.totalPrintLocations}`
                        : ""
                    }${
                      d === "EMB" && data?.totalStitchCount
                        ? `, Stitch Counts : ${data?.totalStitchCount}`
                        : ""
                    }`}
                    key={d}
                    arrow
                  >
                    <DecorationChip
                      inProductionDate={inProductionDate}
                      completedDate={completedDate}
                    >
                      {!!completedDate && (
                        <CheckCircleIcon sx={{ fontSize: "12px" }} />
                      )}

                      {!completedDate && !!inProductionDate && (
                        <CachedIcon sx={{ fontSize: "12px" }} />
                      )}

                      {!completedDate && !inProductionDate && (
                        <InfoIcon sx={{ fontSize: "12px" }} />
                      )}
                      {d}
                    </DecorationChip>
                  </Tooltip>
                );
              })}
            </DecorationChips>

            {!!data?.trackingDetails?.length && (
              <UploadDate>
                Tracking :&nbsp;
                {data?.trackingDetails
                  ?.map((t) => t?.trackingNumber)
                  ?.join(", ")}
              </UploadDate>
            )}
          </Wrapper>

          <ArtProofWrapper artProof={data?.artProof}>
            {data?.status !== inProduction.value && (
              <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
            )}
            {getArtProofStatusTitle(data?.artProof)}
          </ArtProofWrapper>

          {(isOrderCompleted || isOrderInProduction) && !isBotNetworkOrder && (
            <MarkAsShipped
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              {isOrderInProduction ? "Mark Partially Shipped" : "Mark Shipped"}
            </MarkAsShipped>
          )}

          {isOrderCompleted && data?.submitToDecorator && !isBotNetworkOrder && (
            <MarkAsShipped
              onClick={(e) => {
                e.stopPropagation();
                toggleUploadLabelDialog(true);
              }}
            >
              <CloudUploadIcon />
              Upload Shipping Label
            </MarkAsShipped>
          )}

          {isOrderShipped &&
            data?.submitToDecorator &&
            !data?.paymentDetails &&
            !isBotNetworkOrder && (
              <MarkAsShipped
                onClick={(e) => {
                  e.stopPropagation();
                  togglepaymentDetailsDialog(true);
                }}
              >
                <MonetizationOnIcon sx={{ fontSize: "18px" }} />
                Submit Payment
              </MarkAsShipped>
            )}

          {(isUserCsr ||
            isUserDeveloper ||
            user?.email === "brandonv@bigoventees.com") && (
            <StyledCheckIn
              onClick={(e) => {
                e.stopPropagation();
                setMoveOrderDialog(true);
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
              Move Order
              <KeyboardDoubleArrowRightIcon />
            </StyledCheckIn>
          )}

          <CardActions>
            {(isOrderInPreProd || isOrderInProduction || isOrderDefined) &&
              !isBotNetworkOrder && (
                <StyledCheckIn
                  style={getCheckInButton()}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedOrder(data);
                    toggleOrderCheckInDialog(!orderCheckInDialog);
                  }}
                >
                  {isCompletedStockStatus ? (
                    <AssignmentTurnedInIcon
                      className="success"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <ErrorOutlineIcon
                      className="error"
                      style={{ color: "white" }}
                    />
                  )}
                  Check-in
                </StyledCheckIn>
              )}

            <StyledCheckIn
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOrder(data);
                toggleDamageReportDialog(!orderCheckInDialog);
              }}
            >
              <BrokenImageIcon />
              Damage
            </StyledCheckIn>
          </CardActions>
        </Body>
      </Container>

      {attentionMessageDialogVisibility && (
        <AttentionMessageDialog
          open={attentionMessageDialogVisibility}
          setOpen={() => {
            setAttention(false);
            setSelectedOrder(null);
          }}
          order={selectedOrder}
          refresh={refresh}
        />
      )}

      {editOrderDialogVisibility && (
        <EditOrderDialog
          open={editOrderDialogVisibility}
          setOpen={(r) => {
            setEditOrder(false);
            setSelectedOrder(null);
            if (r) refresh();
          }}
          order={selectedOrder}
          refresh={refresh}
        />
      )}
      {open && (
        <OrderMarkShippedDialog
          open={open}
          enablePartialShippingMessage={isOrderInProduction}
          setOpen={setOpen}
          onSubmit={(payload) => {
            const p = {
              ...payload,
              notifyClients: true,
              status: shipped.value,
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };
            setOpen(false);
            updateOrder(p);
          }}
        />
      )}

      {uploadLabelDialog && (
        <UploadShippingLabelDialog
          open={uploadLabelDialog}
          data={data}
          setOpen={toggleUploadLabelDialog}
          onSubmit={(payload) => {
            const p = {
              shippingLabelInformation: {
                ...payload,
              },
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };
            toggleUploadLabelDialog(false);
            updateOrder(p);
          }}
        />
      )}

      {paymentDetailsDialog && (
        <PaymentDetailsDialog
          open={paymentDetailsDialog}
          data={data}
          setOpen={togglepaymentDetailsDialog}
          onSubmit={(payload) => {
            const p = {
              paymentDetails: {
                paymentAmount: payload?.paymentAmount,
                paymentSubmitted: true,
              },
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };
            togglepaymentDetailsDialog(false);
            updateOrder(p);
          }}
        />
      )}

      {commentDialog && (
        <OrderCommentsDialog
          open={commentDialog}
          setOpen={() => toggleCommentDialog(!commentDialog)}
          order={selectedOrder}
          refresh={refresh}
        />
      )}

      {orderCheckInDialog && (
        <OrderCheckInDialog
          open={orderCheckInDialog}
          setOpen={() => toggleOrderCheckInDialog(!orderCheckInDialog)}
          order={selectedOrder}
          refresh={refresh}
        />
      )}

      {damageReportDialog && (
        <DamageReportDialog
          open={damageReportDialog}
          setOpen={() => toggleDamageReportDialog(!damageReportDialog)}
          order={selectedOrder}
          refresh={refresh}
        />
      )}

      {moveOrderDialog && (
        <MoveOrderDialog
          open={moveOrderDialog}
          onClose={() => setMoveOrderDialog(false)}
          orderNumber={data?.orderNumber}
          onMove={(v) => {
            const p = {
              status: v.status.id,
              moveOrder: true,
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };

            updateOrder(p);
          }}
        />
      )}
    </>
  );
};

OrderCard.propTypes = {
  data: PropTypes.shape({
    rushOrder: PropTypes.bool,
    orderCheckInData: PropTypes.arrayOf(PropTypes.shape({})),
    totalStitchCount: PropTypes.number,
    totalPrintLocations: PropTypes.number,
    totalPeiceCount: PropTypes.number,
    flagUrl: PropTypes.string,
    vendorLogo: PropTypes.string,
    decorations: PropTypes.arrayOf(PropTypes.string),
    trackingDetails: PropTypes.arrayOf(
      PropTypes.shape({
        trackingNumber: PropTypes.string,
        shippedDate: PropTypes.string,
      })
    ),
    decoratorData: PropTypes.shape({
      selectedDecorator: PropTypes.shape({ companyName: PropTypes.string }),
    }),
    orderComments: PropTypes.arrayOf(
      PropTypes.shape({ user: PropTypes.string })
    ),
    orderNumber: PropTypes.string,
    submitToDecorator: PropTypes.bool,
    vendorName: PropTypes.string,
    createdDate: PropTypes.number,
    inProductionDate: PropTypes.number,
    shippedDate: PropTypes.number,
    status: PropTypes.oneOf(ORDER_STATUS),
    artProof: PropTypes.shape({
      decision: PropTypes.string,
    }),
    orderEvents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        profileImg: PropTypes.string,
      })
    ),
    paymentDetails: PropTypes.shape({}),
  }),
  handleClick: PropTypes.func,
  refresh: PropTypes.func,
  isBotNetworkOrder: PropTypes.bool,
};

OrderCard.defaultProps = {
  data: {},
  handleClick: () => {},
  refresh: () => {},
  isBotNetworkOrder: false,
};

export default OrderCard;
