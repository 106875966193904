import PropTypes from "prop-types";

const user = PropTypes.shape({
  email: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  companyName: PropTypes.string,
  companyWebsiteUrl: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  status: PropTypes.oneOf([undefined, "Approved", "Declined"]),
  password: PropTypes.string,
});

export const propTypes = {
  state: PropTypes.shape({
    user: PropTypes.arrayOf(user),
    selectedUser: user,
    decoratorAccountDialog: PropTypes.shape({}),
  }),
  setState: PropTypes.func,
};

export const defaultValue = {
  users: undefined,
  setState: () => {},
};
