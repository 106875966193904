import Paper from "@mui/material/Paper";
import TextField from "components/TextFieldInput";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import { StyledShipTo, ShipToRow, WorkOrderHeading } from "styles/pages/order";

const DecoratorWorkOrderShipTo = ({ decoratorData, setDecoratorData }) => {
  const handleShipAddressChange = (key, value) => {
    setDecoratorData({
      ...decoratorData,
      shipAddress: { ...decoratorData?.shipAddress, [key]: value },
    });
  };

  return (
    <Paper elevation={3}>
      <WorkOrderHeading>Ship To</WorkOrderHeading>
      <StyledShipTo>
        <ShipToRow>
          <TextField
            label="Ship Name"
            error={!decoratorData?.shipAddress?.shipName?.trim()}
            value={decoratorData?.shipAddress?.shipName}
            handleChange={(e) =>
              handleShipAddressChange("shipName", e?.target?.value)
            }
          />
        </ShipToRow>

        <ShipToRow>
          <TextField
            label="Address 1"
            error={!decoratorData?.shipAddress?.address1?.trim()}
            value={decoratorData?.shipAddress?.address1}
            handleChange={(e) =>
              handleShipAddressChange("address1", e?.target?.value)
            }
          />

          <TextField
            label="Address 2"
            value={decoratorData?.shipAddress?.address2}
            handleChange={(e) =>
              handleShipAddressChange("address2", e?.target?.value)
            }
          />
        </ShipToRow>

        <ShipToRow style={{ marginBottom: 0 }}>
          <TextField
            label="City"
            error={!decoratorData?.shipAddress?.city?.trim()}
            value={decoratorData?.shipAddress?.city}
            handleChange={(e) =>
              handleShipAddressChange("city", e?.target?.value)
            }
          />

          <TextField
            label="State"
            error={!decoratorData?.shipAddress?.state?.trim()}
            value={decoratorData?.shipAddress?.state}
            handleChange={(e) =>
              handleShipAddressChange("state", e?.target?.value)
            }
          />

          <TextField
            label="Country"
            error={!decoratorData?.shipAddress?.country?.trim()}
            value={decoratorData?.shipAddress?.country}
            handleChange={(e) =>
              handleShipAddressChange("country", e?.target?.value)
            }
          />

          <TextField
            label="Zip"
            error={!decoratorData?.shipAddress?.zip?.trim()}
            value={decoratorData?.shipAddress?.zip}
            handleChange={(e) =>
              handleShipAddressChange("zip", e?.target?.value)
            }
          />
        </ShipToRow>
      </StyledShipTo>
    </Paper>
  );
};

DecoratorWorkOrderShipTo.propTypes = PROP_TYPES;

DecoratorWorkOrderShipTo.defaultProps = DEFAULT_STATE;

export default DecoratorWorkOrderShipTo;
