import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const OrderEstimationLineItemDeleteConfirmation = ({
  open,
  setOpen,
  agree,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Are you sure, you want to delete this line item ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Production Team need this information for stock matching purpose or as
          additional information of the order.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={agree}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

OrderEstimationLineItemDeleteConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  agree: PropTypes.func,
};

OrderEstimationLineItemDeleteConfirmation.defaultProps = {
  open: false,
  setOpen: () => {},
  agree: () => {},
};

export default OrderEstimationLineItemDeleteConfirmation;
