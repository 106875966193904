import styled from "styled-components";
import StepLabel from "@mui/material/StepLabel";
import CommentIcon from "@mui/icons-material/Comment";
import CampaignIcon from "@mui/icons-material/Campaign";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  getBackgroundColorByStatus,
  getBackgroundColorByArtProof,
} from "components/OrderCard/utils";

export const StyledCampaignIcon = styled(CampaignIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    left: -17px;
    top: -17px;
    background: black;
    padding: 4px;
    font-size: 18px;
    border-radius: 2px;
    color: white;
    cursor: pointer;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    left: -17px;
    top: 11px;
    background: black;
    padding: 4px;
    font-size: 18px;
    border-radius: 2px;
    color: white;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  -webkit-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  -moz-box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  box-shadow: 0px 0px 1px 0px rgb(9 30 66 / 31%),
    0 2px 4px -1px rgb(9 30 66 / 25%);
  flex-grow: 1;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  border-left: ${({ rushOrder, status }) =>
    rushOrder ? 0 : `2px solid ${getBackgroundColorByStatus(status)}`};
  transition: transform 0.2s;
  border: ${({ rushOrder }) => (rushOrder ? "2px dashed red" : "-")};
  padding: ${({ rushOrder }) => (rushOrder ? "5px" : 0)};
`;

export const Container = styled.div``;

export const Wrapper = styled.div`
  padding: ${(props) => (props?.artProof ? "10px" : "10px 10px 0")};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  margin-bottom: 10px;
`;

export const PONumber = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  color: #5e6c84;
  font-size: 14px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VendorName = styled.div`
  color: #172b4d;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Status = styled.div`
  background-color: ${({ status }) => getBackgroundColorByStatus(status)};
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 12px;
  color: white;
  font-weight: bold;
`;

export const UploadDate = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #172b4d;
`;

export const InProductionDate = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const ShippedDate = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const ArtProofWrapper = styled.div`
  background-color: ${({ artProof }) => getBackgroundColorByArtProof(artProof)};
  padding: 5px;
  font-size: 12px;
  margin-top: 10px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const VendorLogo = styled.div`
  background-image: url(${(props) => props?.src});
  height: 36px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0;
`;

export const MarkAsShipped = styled.div`
  font-size: 13px;
  padding: 8px;
  font-weight: bold;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

export const StyledCheckIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #000;

  svg.success {
    color: green;
    font-size: 18px;
  }

  svg.error {
    color: orange;
    font-size: 18px;
  }

  :hover {
    background-color: #000;
    color: #fff;
  }
`;

export const MarkShipped = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Comment = styled.div``;

export const StyledCommentIcon = styled(CommentIcon)`
  cursor: pointer;
  color: #a1a1a1;
  font-size: 20px !important;
`;

export const StyledAddCommentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;

  textarea {
    width: 100%;
  }
`;

export const MessageRow = styled.div`
  display: flex;
`;

export const StyledMessage = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #a8ddfd;
  border: 1px solid #97c6e3;
  border-radius: 10px;
  font-size: 12px;
`;

export const StyledMessageContent = styled.p`
  padding-bottom: 14px;
  margin: 0;
`;

export const MessageTimeStamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  bottom: 3px;
  right: 5px;
`;

export const MessageDisplayName = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
`;

export const MessageBody = styled.div`
  width: 100%; ;
`;

export const DecorationChips = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const getChipColorByDates = (data) => {
  if (data?.completedDate) {
    return "#3cad87";
  }

  if (data?.inProductionDate) {
    return "#fb9518";
  }

  return "#a1a1a1";
};

export const DecorationChip = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  background-color: ${(props) => getChipColorByDates(props)};
  color: #fff;
  border-radius: 16px;
  padding: 4px;
  font-weight: bold;
`;

export const Flag = styled.img`
  width: 20px;
  height: 15px;
  margin-top: 4px;
`;

export const CheckedInWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

export const CheckedInForm = styled.div`
  border-right: 1px solid #ccc;
  padding-right: 30px;
  button {
    float: right;
  }
`;

export const CheckedInData = styled.div`
  h3.title {
    margin: 0px 0px 20px;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
  }
`;

export const CompanyName = styled.div`
  background: green;
  color: white;
  font-size: 12px;
  padding: 3px;
  margin-bottom: 5px;
  border-radius: 2px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 10px;
  }
`;

export const UploadShippedWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 20px;
`;

export const StyledParcelInformation = styled.div`
  margin-top: 10px;
`;

export const StyledUploadShippingLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin: 10px 0 0 0;
  }
`;

export const StyledDecoratorName = styled.h4`
  margin: 0;
`;

export const CornerDiv = styled.div`
  border-style: solid;
  border-width: 25px;
  border-color: #008758 transparent transparent #008758;
  left: 0;
  top: 0;
  position: absolute;

  svg {
    position: absolute;
    right: 3px;
    bottom: 1px;
    color: white;
    font-size: 20px;
  }
`;

export const NoDataFound = styled.h2`
  text-align: center;
  color: #a1a1a1;
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
`;

export const CardActions = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
`;

export const DamageItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  padding-bottom: 10px;

  p {
    margin: 0;
  }

  button {
    padding: 5px 20px;
    font-size: 12px;
  }
`;

export const DamagedLineItem = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
  align-items: center;
`;

export const StyledRemoveCircleIcon = styled(RemoveCircleIcon)`
  color: red;
  cursor: pointer;
`;

export const Row = styled.div`
  border: 1px dashed #ccc;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  border-radius: 8px;
`;

export const RowHeader = styled.div`
  display: flex;
  gap: 3px;
  font-weight: bold;
  flex-direction: column;
  font-size: 12px;

  svg.MuiSvgIcon-root {
    font-size: 16px !important;
  }
  div.MuiStepConnector-root {
    top: 8px !important;
  }
`;
export const SubRow = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    margin-top: 10px !important;
    font-size: 12px !important;
  }
`;

export const TrackingNumber = styled.div`
  background: green;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
`;

export const OrderDetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
`;

export const Title = styled.h3`
  margin: 5px 0 12px;
  padding-bottom: 4px;
  border-bottom: 1px solid black;
`;

export const Note = styled.div`
  background: #ccc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  .note {
    font-size: 14px;
  }
  .time {
    text-align: right;
    font-size: 10px;
  }
`;
