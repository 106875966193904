import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import moment from "moment";
import { propTypes, defaultValue } from "pages/bot-network/types";
import LOGO_BASE64 from "pages/bot-network/SalesReportDialog/logo";
import { BOT_CA_ADDRESS, BOT_USA_ADDRESS } from "constants/address";
import styles from "pages/bot-network/SalesReportDialog/styles";

const {
  header,
  subTitle,
  title,
  paidTo,
  tableHead,
  tableHeadText,
  tableBody,
  tableBodyText,
  heading,
  image,
  tableHeadDescriptionText,
  tableBodyDescriptionText,
  footer,
  devider,
} = styles;

const subtotal = (items) =>
  items.map(({ chargeAmount }) => chargeAmount).reduce((sum, i) => sum + i, 0);

const SalesReportPdf = ({ state }) => {
  const user = state?.salesReportDialog;
  const taxRate = user?.taxRate || 0;
  const orders = user?.orders;

  const deductionDescription = user?.deductionDescription;
  const deductionAmount = user?.deductionAmount || 0;

  const invoiceSubtotal = subtotal(orders) - deductionAmount;
  const invoiceTaxes = (taxRate * invoiceSubtotal) / 100;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const {
    address1,
    address2,
    companyName,
    city,
    country,
    state: stateProvince,
    zip,
    email,
  } = user;

  const botAddress = country === "Canada" ? BOT_CA_ADDRESS : BOT_USA_ADDRESS;
  const botNetworkUserEmail =
    email === "botorders@glprintingservices.com"
      ? "accounting@glprintingservices.com"
      : email;

  return (
    <Document>
      <Page style={{ paddingVertical: 20 }}>
        <View style={header}>
          <View>
            <Text style={title}>{botAddress?.comapnyName}</Text>
            <Text style={subTitle}>{botAddress.addressLineOne}</Text>
            <Text style={subTitle}>
              {botAddress?.city} {botAddress?.state} {botAddress?.postalCode}
            </Text>
            <Text style={subTitle}>{botAddress?.country}</Text>
            <Text style={subTitle}>{botAddress?.email}</Text>
          </View>
          <View>
            <Image style={image} src={LOGO_BASE64} />
          </View>
        </View>

        <Text style={heading}>Payment Voucher</Text>

        <View style={header}>
          <View style={paidTo}>
            <Text style={title}>Paid To</Text>
            <Text style={subTitle}>{companyName}</Text>
            <Text style={subTitle}>
              {address1} {address2}
            </Text>
            <Text style={subTitle}>
              {city} {stateProvince} {zip}
            </Text>
            <Text style={subTitle}>{country}</Text>
            <Text style={subTitle}>{botNetworkUserEmail}</Text>
          </View>

          <View style={paidTo}>
            <Text style={title}>Payment For</Text>
            <Text style={subTitle}>
              {moment.unix(user?.startDate).format("MMM DD, YYYY")} -{" "}
              {moment.unix(user?.endDate).format("MMM DD, YYYY")}
            </Text>
          </View>
        </View>

        <View style={devider} />

        <View style={tableHead}>
          <Text style={tableHeadText}>Order #</Text>
          <Text style={tableHeadDescriptionText}>Description</Text>
          <Text style={tableHeadText}>Color</Text>
          <Text style={tableHeadText}>Size</Text>
          <Text style={tableHeadText}>Deco</Text>
          <Text style={tableHeadText}>Qty</Text>
          <Text style={tableHeadText}>Amount</Text>
        </View>

        {orders?.map((l, i) => {
          const key = `lineItems${i}`;
          return (
            <View key={key}>
              <View style={tableBody}>
                <Text style={tableBodyText}>{l?.orderNumber}</Text>
                <Text style={tableBodyDescriptionText}>{l?.description}</Text>
                <Text style={tableBodyText}>{l?.color}</Text>
                <Text style={tableBodyText}>{l?.size}</Text>
                <Text style={tableBodyText}>{l?.decorationInput}</Text>
                <Text style={tableBodyText}>{l?.qty}</Text>
                <Text style={tableBodyText}>{l?.chargeAmount}</Text>
              </View>
            </View>
          );
        })}

        <View style={styles.deductionWrapper}>
          <Text style={{ ...styles?.descriptionText, width: "50%" }}>
            {deductionDescription || "NA"}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.totalCost}>Deduction Amount :</Text>
            <Text>${deductionAmount.toFixed(2)}</Text>
          </View>
        </View>

        <View style={styles.totalCostWrapper}>
          <Text style={styles.totalCost}>Sub Total :</Text>
          <Text>${invoiceSubtotal.toFixed(2)}</Text>
        </View>

        <View style={styles.totalCostWrapper}>
          <Text style={styles.totalCost}>Tax ({taxRate}%) :</Text>
          <Text>{invoiceTaxes.toFixed(2)}</Text>
        </View>

        <View style={styles.totalCostWrapper}>
          <Text style={styles.totalCost}>Total :</Text>
          <Text>${invoiceTotal.toFixed(2)}</Text>
        </View>

        <Text style={footer}>
          Please email us at customerservice@bigoventees.com if you have any
          questions or concerns.
        </Text>
      </Page>
    </Document>
  );
};

SalesReportPdf.propTypes = propTypes;

SalesReportPdf.defaultProps = defaultValue;

export default SalesReportPdf;
