import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "components/TextFieldInput";
import { StyledDecoratorName } from "styles/components/order-card";

const PaymentDetailsDialog = ({ open, setOpen, onSubmit, data }) => {
  const [state, setState] = useState();
  const decoratorData = data?.decoratorData;
  const handleClose = () => {
    setState();
    setOpen(false);
  };

  const getTotalAmount = () => {
    let totalAmount = 0;

    for (let i = 0; i < decoratorData?.lineItems.length; i += 1) {
      totalAmount += decoratorData?.lineItems[i]?.chargeAmount || 0;
    }
    for (let i = 0; i < decoratorData?.otherCharges.length; i += 1) {
      totalAmount += decoratorData?.otherCharges[i]?.chargeAmount || 0;
    }

    return totalAmount;
  };

  useEffect(() => {
    setState({ ...state, paymentAmount: getTotalAmount() });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Payment Submit To Decorator</DialogTitle>
      <DialogContent>
        <StyledDecoratorName>
          {data?.decoratorData?.selectedDecorator?.companyName}
        </StyledDecoratorName>

        <TextField
          style={{ marginTop: "20px" }}
          label="Payable Amount (USD)"
          fullWidth
          value={state?.paymentAmount}
          type="Number"
          handleChange={(e) =>
            setState({ ...state, paymentAmount: Number(e?.target?.value) })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!state?.paymentAmount}
          onClick={() => {
            onSubmit(state);
            setState();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaymentDetailsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    decoratorData: PropTypes.shape({
      selectedDecorator: PropTypes.shape({
        companyName: PropTypes.string,
      }),
    }),
  }),
};

PaymentDetailsDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  onSubmit: () => {},
  data: { decoratorData: {} },
};

export default PaymentDetailsDialog;
