import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldInput from "components/TextFieldInput";
import Checkbox from "components/Checkbox";
import { usePost } from "hooks/usePost";
import openCloudinaryWidget from "utils/cloudinary";
import { propTypes, defaultProps } from "pages/vendors/types";
import { VENDORS_SERVICE } from "constants/services";
import {
  StyledAvatarWrapper,
  StyledAvatar,
  UploadImageButton,
  Note,
  Row,
} from "styles/pages/vendors";

const AddVendorDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const addvendorPayload = state?.addvendorPayload;

  const handleClose = () => {
    setState({ ...state, addvendorPayload: undefined });
  };

  if (!addvendorPayload) return null;
  const { logoUrl, vendorName, vendorFullName } = addvendorPayload;

  const disabled = !logoUrl || !vendorFullName;

  const onCreate = async () => {
    const { data } = await mutateAsync({
      url: VENDORS_SERVICE,
      isPut: addvendorPayload?.update,
      data: {
        ...addvendorPayload,
        scanning: addvendorPayload?.update ? addvendorPayload?.scanning : false,
      },
    });

    if (data?.success) {
      setState({ ...state, addvendorPayload: undefined, vendors: undefined });
    }
  };

  return (
    <Dialog
      open={!!addvendorPayload}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {addvendorPayload?.update ? "Update" : "Add"} Vendor
      </DialogTitle>
      <DialogContent>
        <StyledAvatarWrapper>
          {!!logoUrl && <StyledAvatar src={logoUrl} alt="logo" />}
          <UploadImageButton
            variant="contained"
            disabled={!vendorFullName}
            onClick={() => {
              openCloudinaryWidget(
                `ConfluenceApp/Vendors/${vendorName}`,
                [],
                (v) => {
                  setState({
                    ...state,
                    addvendorPayload: {
                      ...state?.addvendorPayload,
                      logoUrl: v?.secure_url,
                    },
                  });
                }
              );
            }}
          >
            Upload Image
          </UploadImageButton>
        </StyledAvatarWrapper>
        <Row>
          <TextFieldInput
            label="Vendor Name"
            disabled
            value={vendorName}
            handleChange={() => {}}
          />
          <Note>This is unique and cannot be changeable.</Note>
        </Row>

        <Row>
          <TextFieldInput
            label="Vendor Full Name"
            value={vendorFullName}
            handleChange={(e) => {
              const value = {
                ...state?.addvendorPayload,
                vendorFullName: e?.target?.value,
              };

              if (!addvendorPayload?.update) {
                value.vendorName = e?.target?.value?.replace(/ /g, "_");
              }

              setState({ ...state, addvendorPayload: value });
            }}
          />
        </Row>

        <Row>
          <Checkbox
            disabled={!addvendorPayload?.update}
            label="Scanning Complete"
            checked={addvendorPayload.scanning}
            onChange={(v) =>
              setState({
                ...state,
                addvendorPayload: {
                  ...state?.addvendorPayload,
                  scanning: v,
                },
              })
            }
          />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onCreate} disabled={disabled}>
          {addvendorPayload?.update ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddVendorDialog.propTypes = propTypes;

AddVendorDialog.defaultProps = defaultProps;

export default AddVendorDialog;
