import PropTypes from "prop-types";
import openCloudinaryWidget from "utils/cloudinary";
import {
  Container,
  StyledImage,
  StyledFileImage,
  StyledEditIcon,
} from "styles/components/upload-image";

const UploadImage = ({
  size,
  url,
  folder,
  setUrl,
  disabled,
  showPencilIcon,
}) => (
  <Container
    size={size}
    onClick={() => {
      if (disabled) return;
      openCloudinaryWidget(folder, [], (v) => setUrl(v));
    }}
  >
    {!!url && <StyledImage src={url?.replace(".pdf", ".png")} alt="url" />}
    {!url && <StyledFileImage size={size} />}
    {!disabled && showPencilIcon && <StyledEditIcon size={size} />}
  </Container>
);

UploadImage.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  url: PropTypes.string,
  folder: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showPencilIcon: PropTypes.bool,
};

UploadImage.defaultProps = {
  size: "md",
  url: undefined,
  disabled: false,
  showPencilIcon: true,
};

export default UploadImage;
