import { useEffect, useState, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import Header from "pages/vendors/Header";
import { useFetch } from "hooks/useFetch";
import {
  VENDORS_SERVICE,
  CONFLUENCE_VENDORS_USER_LIST,
} from "constants/services";
import { VENDORS_USERS } from "constants/routes";
import {
  StyledContainer,
  StyledVendors,
  StyledVendor,
  StyledFlag,
  StyledvendorName,
  StyledEditIcon,
} from "styles/pages/vendors";

const VendorsPage = () => {
  const csvEl = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [csvData, setCsvData] = useState();
  const { refetch } = useFetch(VENDORS_SERVICE, VENDORS_SERVICE, {
    enabled: false,
  });

  const { refetch: vendorsUserListRefetch } = useFetch(
    CONFLUENCE_VENDORS_USER_LIST,
    CONFLUENCE_VENDORS_USER_LIST,
    {
      enabled: false,
    }
  );

  const fetchVendors = async () => {
    const { data } = await refetch();

    if (data) {
      setState({ ...state, vendors: data });
    }
  };

  useEffect(() => {
    if (!state?.vendors) fetchVendors();
  }, [state]);

  return (
    <StyledContainer fixed>
      <Header
        state={state}
        setState={setState}
        onVendorsUsersList={async () => {
          const { data } = await vendorsUserListRefetch();

          if (data?.length > 0) {
            const rows = [["Vendor Name", "Client Email"]];

            for (let i = 0; i < data.length; i += 1) {
              const { clientEmail, vendorName } = data[i];
              if (clientEmail) {
                rows.push([vendorName, clientEmail]);
              }
            }

            setCsvData(rows);
            setTimeout(() => {
              csvEl.current.link.click();
            }, 10);
          } else {
            // eslint-disable-next-line
            alert("No data found!");
          }
        }}
      />

      <StyledVendors>
        {!!state?.vendors?.length &&
          state?.vendors?.map((vendor) => {
            const { name, printProvider, vendorFullName } = vendor;
            return (
              <StyledVendor
                key={name}
                onClick={() => navigate(`${VENDORS_USERS}/${vendor?.name}`)}
              >
                <StyledEditIcon
                  onClick={(e) => {
                    e?.stopPropagation();

                    setState({
                      ...state,
                      addvendorPayload: { ...vendor, update: true },
                    });
                  }}
                />
                <Tooltip title={printProvider} arrow>
                  <StyledFlag
                    alt="flag"
                    src={
                      printProvider === "CA"
                        ? "https://www.shirtly.com/static/media/canada-flag.ed12b373.png"
                        : "https://www.shirtly.com/static/media/usa-flag.dcbc42c5.png"
                    }
                  />
                </Tooltip>

                <img
                  src={vendor?.logoUrl}
                  alt="logo-url"
                  style={{ width: "90px" }}
                />

                <StyledvendorName>{vendorFullName}</StyledvendorName>
              </StyledVendor>
            );
          })}
      </StyledVendors>

      {csvData && (
        <CSVLink data={csvData} ref={csvEl} filename="Vendors_User_List.csv" />
      )}
    </StyledContainer>
  );
};

export default VendorsPage;
