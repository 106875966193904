import { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import Header from "pages/bot-network/Header";
import Users from "pages/bot-network/Users";
import { useFetch } from "hooks/useFetch";
import { DEVELOPER_ROLE } from "constants/roles";
import { BOT_NETWORK_USERS } from "constants/services";
import { StyledContainer } from "styles/pages/bot-network";

const BotNetwork = () => {
  const { refetch } = useFetch(BOT_NETWORK_USERS, BOT_NETWORK_USERS, {
    enabled: false,
  });
  const [state, setState] = useState();
  const user = useSelector((s) => s?.user);

  const fetchUsers = async () => {
    const { data } = await refetch();
    if (data) setState({ ...state, users: _.sortBy(data, ["companyName"]) });
  };

  useEffect(() => {
    if (!state?.users) fetchUsers();
  }, [state?.users]);

  if (user?.role !== DEVELOPER_ROLE.role) {
    return null;
  }

  return (
    <StyledContainer fixed>
      <Header />
      <Users state={state} setState={setState} />
    </StyledContainer>
  );
};

export default BotNetwork;
